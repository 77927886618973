/**
 * @prettier
 * @flow
 * */

import type { AnyStoreItemType } from '../../../../utils/types';
import type { CartItems, StorageCartService } from '../types';
import type {
  CartResponse,
  CartSuccess,
  CartWarnings,
  StoreDRMType,
} from '@site-builder/common/src/flow-types/store';

import {
  filterWarnings,
  transformFieldsFromStoreToCart,
} from '../../../../utils/store-helper';
import { storeItemFactory } from '../../../../utils/store/store-item-factory';
import {
  addItemToCart,
  cancelRedeemPromocode,
  clearCurrentUsersCart,
  deleteItemFromCurrentUsersCart,
  getRewardPromocode,
  getUsersCart,
  redeemPromocode,
  saveItemsToCart,
} from '../api';
import { CartItem } from '../cart/CartItem';
import { cartItemFactory } from '../cart/cartItemFactory';
import { GameKeyCartItem } from '../cart/GameKeyCartItem';
import { StoreAPIDataService } from './StoreAPIDataService';

export class StoreAPICartService implements StorageCartService {
  _userToken: string;

  _projectId: string;

  _cartId: string;

  _warnings: CartWarnings;

  _dataAPIService: StoreAPIDataService;

  _isAuth: boolean;

  warnings(): CartWarnings {
    return this._warnings;
  }

  constructor(
    userToken: string,
    projectId: string,
    cartId: string,
    dataAPIService: StoreAPIDataService,
    isAuth: boolean
  ) {
    this._userToken = userToken;
    this._projectId = projectId;
    this._cartId = cartId;
    this._dataAPIService = dataAPIService;
    this._warnings = [];
    this._isAuth = isAuth;
  }

  get userToken() {
    return this._userToken;
  }

  get cartId() {
    return this._cartId;
  }

  _processApiItems(items: Array<AnyStoreItemType | StoreDRMType>) {
    return items.map<AnyStoreItemType | StoreDRMType>((item) => {
      const storeItem = storeItemFactory(this._dataAPIService.landingType)(
        item
      );
      let factoryProps = {
        ...transformFieldsFromStoreToCart(item),
        sku: storeItem.parentSKU,
        // $FlowFixMe
        selectedDRM: storeItem.sku,
      };
      const data = this._dataAPIService.findBySku(storeItem.parentSKU);

      if (data) {
        const camelCasedData = transformFieldsFromStoreToCart(data);
        factoryProps = {
          ...factoryProps,
          unitItems: camelCasedData.unitItems,
        };
      }
      // $FlowFixMe
      return cartItemFactory(factoryProps);
    });
  }

  async load() {
    const response = await getUsersCart({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      isAuth: this._isAuth,
    });
    if (response.errorCode) {
      return response;
    }
    const cart: CartSuccess = {
      price: response.price,
      items: this._processApiItems(response.items),
    };
    return cart;
  }

  async save(value: CartItems) {
    if (value.length === 0) {
      return {
        items: [],
        price: null,
      };
    }
    const paidItems = value
      .filter((item) => !item.isBonus)
      .map((item) => item.toStoreObject());
    const cart = await saveItemsToCart({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      items: paidItems,
      isAuth: this._isAuth,
    });
    if (cart.errorMessage) {
      return value;
    }

    this.fillWarnings(cart);
    return {
      items: this._processApiItems(cart.items),
      price: cart.price,
    };
  }

  add({ sku, quantity }: { sku: string, quantity: number }) {
    return addItemToCart({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      sku,
      isAuth: this._isAuth,
      itemsQuantity: quantity,
    });
  }

  clearAll() {
    this._warnings = [];
    return clearCurrentUsersCart({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      isAuth: this._isAuth,
    });
  }

  delete(deletedItem: GameKeyCartItem | CartItem) {
    let deletedSku;
    if (deletedItem instanceof GameKeyCartItem) {
      deletedSku = deletedItem.selectedDRM;
    } else {
      deletedSku = deletedItem.sku;
    }
    return deleteItemFromCurrentUsersCart({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      sku: deletedSku,
      isAuth: this._isAuth,
    });
  }

  fillWarnings(cart: CartResponse) {
    if (this.warnings().length === 0) {
      this._warnings = cart.warnings ? filterWarnings(cart.warnings) : [];
    }
  }

  deleteBySku(deletedSku: string) {
    return deleteItemFromCurrentUsersCart({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      sku: deletedSku,
      isAuth: this._isAuth,
    });
  }

  rewardPromocode(promocode: string) {
    return getRewardPromocode({
      userToken: this._userToken,
      projectId: this._projectId,
      isAuth: this._isAuth,
      promocode,
      cartId: this._cartId,
    });
  }

  async redeemPromocode(
    promocode: string,
    selectedUnitItems?: { [key: string]: string }
  ) {
    const response = await redeemPromocode({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      isAuth: this._isAuth,
      promocode,
      selectedUnitItems,
    });
    if (response.errorCode) {
      return response;
    }
    return {
      price: response.price,
      items: this._processApiItems(response.items),
    };
  }

  async cancelPromocode() {
    const response = await cancelRedeemPromocode({
      userToken: this._userToken,
      projectId: this._projectId,
      cartId: this._cartId,
      isAuth: this._isAuth,
    });
    if (response.errorCode) {
      return response;
    }
    return {
      price: response.price,
      items: this._processApiItems(response.items),
    };
  }
}
