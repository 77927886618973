import {
  allowedLanguages,
  FiveSymbolLocale,
  fiveSymbolsMap,
  TwoSymbolLocale,
} from '@site-builder/common/src/types/locale';
import Cookie from 'js-cookie';
import { addLocaleData } from 'react-intl';

import EmptySpan from '../EmptySpan';
import { twoSymbolsMapForPublisher } from '../locales-map';

export const defaultLocale = 'en-US';
export const getDefaultMessages = (): Record<string, string> =>
  require('../../translations/en-US');

// этот импорт должен быть без переменных
export const defaultLocaleWithoutRegionCode = 'en';

const cookieLocale = Cookie.get('pa-locale') as TwoSymbolLocale;

export const detectUserLocale = (): FiveSymbolLocale =>
  twoSymbolsMapForPublisher[`${cookieLocale}`]
    ? twoSymbolsMapForPublisher[`${cookieLocale}`]
    : defaultLocale;

export const intlAddLocaleData = async () => {
  type TwoSymbolLocaleWithZh = TwoSymbolLocale | 'zh';
  const twoSymbolLocale =
    cookieLocale && twoSymbolsMapForPublisher[`${cookieLocale}`]
      ? cookieLocale
      : (defaultLocaleWithoutRegionCode as TwoSymbolLocaleWithZh);

  const loadLocaleData = (data) => addLocaleData([data.default]);
  switch (twoSymbolLocale) {
    case 'en':
      await import(`react-intl/locale-data/en.js`).then(loadLocaleData);
      break;
    case 'ru':
      await import(`react-intl/locale-data/ru.js`).then(loadLocaleData);
      break;
    case 'ko':
      await import(`react-intl/locale-data/ko.js`).then(loadLocaleData);
      break;
    case 'zh':
      await import(`react-intl/locale-data/zh.js`).then(loadLocaleData);
      break;
    case 'de':
      await import(`react-intl/locale-data/de.js`).then(loadLocaleData);
      break;
    case 'ja':
      await import(`react-intl/locale-data/ja.js`).then(loadLocaleData);
      break;
    default:
      break;
  }
};

export const getTranslationsData = (locale) => {
  const fileName =
    locale || twoSymbolsMapForPublisher[`${defaultLocaleWithoutRegionCode}`];
  return import(`../../translations/${fileName}.js`);
};

export const setHtmlLangFromCookie = () => {
  const twoSymbolLocale =
    cookieLocale && twoSymbolsMapForPublisher[`${cookieLocale}`]
      ? cookieLocale
      : defaultLocaleWithoutRegionCode;
  const htmlElement = document.querySelector('html');
  htmlElement?.setAttribute('lang', twoSymbolLocale);
};

let apiTranslations;
export const importApiTranslations = async () => {
  const promises = allowedLanguages.map((locale) =>
    import(`../../../../server/src/translations/${locale}.js`).catch(() => null)
  );
  const modules = await Promise.all(promises);
  apiTranslations = allowedLanguages.reduce(
    (translations, languageKey, index) => ({
      ...translations,
      [languageKey]: modules[+index]?.default ?? null,
    }),
    {}
  );
};

const insertValues = (sourceString, values) => {
  let labelText = sourceString;
  Object.keys(values).forEach((key) => {
    const regexp = new RegExp(`{${key}}`, 'g');
    labelText = labelText.replace(regexp, values[`${key}`]);
  });
  return labelText;
};

const dataAttribute = 'data-translation-data-json';
const camelCaseDataAttribute = 'translationDataJson';

export const setLabelsToSSRLanding = (translations: Record<string, string>) => {
  const elements = document.querySelectorAll(
    `[${dataAttribute}]`
  ) as NodeListOf<HTMLElement>;

  elements.forEach((element) => {
    const jsonData = element.dataset[`${camelCaseDataAttribute}`] as string;
    const { label, values } = JSON.parse(jsonData);
    const labelText = insertValues(translations[`${label}`], values);
    element.replaceWith(labelText);
  });
};

export const convertFiveSymbolsToTwoSymbolsLocale = (
  language: FiveSymbolLocale
) => {
  try {
    if (
      language === null ||
      !language ||
      Array.isArray(language) ||
      language === Object(language)
    ) {
      return defaultLocaleWithoutRegionCode;
    }

    const localeAr = language.split('-');

    if (localeAr.length > 1) {
      return fiveSymbolsMap[`${language}`];
    }
    return defaultLocaleWithoutRegionCode;
  } catch (e) {
    return defaultLocaleWithoutRegionCode;
  }
};

export const getPreviewLabels =
  (label: string) =>
  (locale: FiveSymbolLocale, values = {}) => {
    const isServerSideRender =
      !apiTranslations && typeof window === 'undefined';
    if (isServerSideRender) {
      return EmptySpan({ [dataAttribute]: JSON.stringify({ label, values }) });
    }

    const labelText: string = apiTranslations
      ? apiTranslations[`${locale}`]?.[`${label}`] ||
        apiTranslations[`${defaultLocale}`][`${label}`]
      : window.__TRANSLATIONS__[`${label}`];

    return insertValues(labelText, values);
  };
