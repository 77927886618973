import * as Sentry from '@sentry/browser';

type SentryError = Error | string;

class SentryLogger {
  constructor(private filename: string) {}

  error(error: SentryError, ...args: any[]) {
    this.trackError(error, Sentry.Severity.Error, args);
  }

  warn(error: SentryError, ...args: any[]) {
    this.trackError(error, Sentry.Severity.Warning, args);
  }

  info(error: SentryError, ...args: any[]) {
    this.trackError(error, Sentry.Severity.Info, args);
  }

  debug(error: SentryError, ...args: any[]) {
    this.trackError(error, Sentry.Severity.Debug, args);
  }

  private trackError(error: SentryError, level: Sentry.Severity, args: any[]) {
    Sentry.withScope((scope) => {
      scope.setLevel(level);

      scope.setExtra('filename', this.filename);

      if (args.length) {
        scope.setExtra('extra', args);
      }

      Sentry.captureException(error);
    });
  }
}

export const LoggerFactory = {
  getLogger(filename: string) {
    return new SentryLogger(filename);
  },
};
