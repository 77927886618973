import { selectDocumentLogin } from '@site-builder/common/src/data-selectors/document';
import { RenderMode } from '@site-builder/common/src/flow-types/build';
import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';

import type { Action, Dispatch, GetState } from '../../types';
import type { LandingState } from '../../types/landing-state';
import type { RootState } from './index';
import type { UserSessionResponse } from '@site-builder/common/src/flow-types/api/session';
import type { IRenderMode } from '@site-builder/common/src/flow-types/build';
import type { FiveSymbolLocale } from '@site-builder/common/src/flow-types/locale';
import type {
  Landing,
  LandingWithStructure,
} from '@site-builder/common/src/flow-types/model/landing';
import type { ProjectDetails } from '@site-builder/common/src/flow-types/model/project';
import type { AxiosResponse } from 'axios';

import * as Api from '../../../utils/api';
import { fetchAssets } from './assets';
import { initBlocks, setBlocks } from './blocks';
import { initFeatures } from './features';
import { initHiddenBlocks } from './hiddenBlocks';
import { initLauncherData, initNewsArticles } from './launcherData';
import { fetchLoginList, fetchLoginWidgetSettings } from './loginData';
import { initStoreItemsGroupsData } from './oldStoreData';
import { initPages } from './pages';
import { setProjectInfo } from './projectInfo';
import { initRetailers } from './retailers';
import { initSavingProcess, dataSaved, dataDontSave } from './spaces';
import { fetchUsedStoreSections } from './store/action-creators';
import { initSubscriptionData } from './subscriptionData';

export const INIT_LANDING = 'INIT_LANDING';
export const SET_PREVIEW = 'SET_PREVIEW';
export const SET_MULTIPAGE_MODE = 'SET_MULTIPAGE_MODE';
export const LANDING_IS_LOAD = 'LANDING_IS_LOAD';
export const END_LOADING = 'END_LOADING';
export const UPDATE_LANDING = 'UPDATE_LANDING';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const SET_ERROR = 'SET_ERROR';
export const FETCH_AVAILABLE_COMPONENTS = 'FETCH_AVAILABLE_COMPONENTS';

const initialState: LandingState = {
  // $FlowFixMe здесь проблема в том, что мы не проверяем наличие landing, когда получаем структуру из getState, нужно найти решение
  landing: Object.create({}),
  availableComponents: [],
  locale: 'en-US',
  loading: true,
  isIframe: false,
  renderMode: RenderMode.EDITING,
  isServerSideRender: false,
  isMultipage: false,
  error: false,
  loadStatus: 200,
  userSubscription: undefined,
  hiddenBlocks: Object.create({}),
};

export const selectMerchantId = (landingState: LandingState) =>
  landingState.landing.merchantId;

export const selectLandingLanguages = (landingState: LandingState) =>
  landingState.landing.languages;

export const selectMultipageFlag = (landingState: LandingState) =>
  landingState.isMultipage;

export const selectLandingType = (landingState: LandingState) =>
  landingState.landing.type;

export const selectProjectId = (landingState: LandingState) =>
  landingState.landing.projectId;

export const initLanding = (landingData: Landing) => {
  const landing = { ...landingData };
  delete landing.pages;

  return {
    type: INIT_LANDING,
    landing,
  };
};

export const setMultipageMode = (isMultipage: boolean) => ({
  type: SET_MULTIPAGE_MODE,
  payload: { isMultipage },
});

export const fetchAvailableComponents =
  (landing: Landing) => async (dispatch: Dispatch) => {
    const { merchantId, projectId, _id: landingId } = landing;
    const response = await Api.getAvailableComponents({
      merchantId,
      projectId,
      landingId,
    });
    if (response?.status === 200) {
      dispatch({
        type: FETCH_AVAILABLE_COMPONENTS,
        payload: { availableComponents: [...response.data] },
      });
    }
  };

export const setPreview = ({
  renderMode,
  userSubscription,
}: {
  renderMode: IRenderMode;
  userSubscription: string;
}) => ({
  type: SET_PREVIEW,
  payload: {
    renderMode,
    isServerSideRender: true,
    userSubscription,
  },
});

export const setError = (status: number) => ({
  type: SET_ERROR,
  loadStatus: status,
  error: true,
});

export const startLoading = () => (dispatch: Dispatch) => {
  dispatch({
    type: LANDING_IS_LOAD,
    loading: true,
  });
};

export const endLoading = () => (dispatch: Dispatch) => {
  dispatch({
    type: END_LOADING,
    loading: false,
    isIframe: typeof window !== 'undefined' && window.self !== window.top,
  });
};

export const setLandingData =
  (landing: LandingWithStructure) => (dispatch: Dispatch) => {
    dispatch(initLanding(landing));
    dispatch(initBlocks(landing));
    dispatch(initPages(landing));
    dispatch(initFeatures(landing));
    // TODO сделать инициаилизацию загрузки лаунчера зависимой от наличия блока новостей и тд
    dispatch(initLauncherData(landing));
    dispatch(initNewsArticles(landing));
    dispatch(fetchAssets(landing));
    dispatch(fetchLoginList(landing));
    dispatch(fetchAvailableComponents(landing));
    dispatch(fetchLoginWidgetSettings(selectDocumentLogin(landing)));
    dispatch(initSubscriptionData(landing));
    dispatch(initStoreItemsGroupsData(landing));
    dispatch(fetchUsedStoreSections());
    dispatch(initRetailers());
    dispatch(initHiddenBlocks());
  };

export const serverSetLandingData =
  (data: LandingWithStructure) => (dispatch: Dispatch) => {
    dispatch(initLanding(data));
    dispatch(initBlocks(data));
    dispatch(initPages(data));
    dispatch(endLoading());
  };

export const publishLanding =
  () => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, domain } = landing;

    return Api.publicationLanding({ merchantId, projectId, domain });
  };

export const checkCorrectSettings =
  () => async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      blocks: { blocks },
      landing: { landing },
      pages: { pages, currentPage },
      projectInfoData,
    } = getState();
    const { merchantId, projectId, _id: landingId } = landing;

    const removeStoreBlocks = async () => {
      const storeBlockIds = blocks.reduce(
        (accumulator, block) =>
          block.module === BlockModule.STORE
            ? accumulator.concat(block._id)
            : accumulator,
        []
      );
      if (!storeBlockIds.length) {
        return;
      }

      const pageId = pages[currentPage]._id;
      await Promise.all(
        storeBlockIds.map((blockId) =>
          Api.deletePageBlock({
            merchantId,
            projectId,
            landingId,
            pageId,
            blockId,
          })
        )
      );

      const updatedBlocks = blocks.filter(
        (block) => block.module !== BlockModule.STORE
      );
      dispatch(setBlocks(updatedBlocks));
    };

    const isCreatedStructure = !!pages.length;
    if (isCreatedStructure) {
      const isOldScheme = !projectInfoData.isUniversalItemEnabled;
      if (isOldScheme) {
        await removeStoreBlocks();
      }
    }

    dispatch(endLoading());
  };

// TODO продумать единый вид обработки ошибок
export const getDataForOpenConstructor =
  (domainWindow: string) => async (dispatch: Dispatch) => {
    const responseSession: AxiosResponse<UserSessionResponse> =
      await Api.sessionStart({ domain: domainWindow });
    if (responseSession?.status !== 200 || !responseSession?.data?.landing) {
      dispatch(setError(responseSession?.status || 500));
      return;
    }

    const { merchantId, projectId, domain, landingId } =
      responseSession.data.landing;
    const responses: [
      AxiosResponse<LandingWithStructure>,
      AxiosResponse<ProjectDetails>
    ] = await Promise.all([
      Api.getStructure({ merchantId, projectId, domain }),
      Api.getProjectInfo({ merchantId, projectId, landingId }),
    ]);
    const error = responses.find(
      (response) => response?.status !== 200 || !response?.data
    );
    if (error) {
      dispatch(setError(error?.status || 500));
      return;
    }

    const [responseStructure, responseProjectInfo] = responses;
    dispatch(setLandingData(responseStructure.data));
    dispatch(setMultipageMode(responseSession.data.landing.isMultipage));
    dispatch(setProjectInfo(responseProjectInfo.data));
    dispatch(checkCorrectSettings());
  };

export const changeLocale =
  (locale: FiveSymbolLocale) => (dispatch: Dispatch) => {
    dispatch(fetchUsedStoreSections(locale));
    dispatch({
      type: CHANGE_LOCALE,
      locale,
    });
  };

export const addLanguage =
  (language: FiveSymbolLocale) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, domain } = landing;

    dispatch(initSavingProcess());
    try {
      const response: AxiosResponse<LandingWithStructure> =
        await Api.addLanguageToLanding({
          merchantId,
          projectId,
          domain,
          data: { language },
        });
      if (!response?.data) {
        throw new Error('Incorrect backend response when adding a language');
      }

      const { data } = response;
      dispatch(dataSaved());
      dispatch(initBlocks(data));
      dispatch(initPages(data));
      dispatch({
        type: ADD_LANGUAGE,
        payload: {
          landing: data,
          locale: language,
        },
      });
      dispatch(fetchUsedStoreSections(language));
    } catch (error) {
      dispatch(dataDontSave());
    }
  };

export const deleteLanguage =
  (language: FiveSymbolLocale) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, domain } = landing;

    dispatch(initSavingProcess());
    try {
      const response: AxiosResponse<any> = await Api.deleteLanguageFromLanding({
        merchantId,
        projectId,
        domain,
        data: { language },
      });
      if (!response?.data) {
        throw new Error('Incorrect backend response when deleting a language');
      }

      dispatch(dataSaved());
      const updatedLanguagesList = response.data;
      dispatch({
        type: DELETE_LANGUAGE,
        payload: {
          landing: { ...landing, languages: updatedLanguagesList },
          locale: updatedLanguagesList[updatedLanguagesList.length - 1],
        },
      });
    } catch (error) {
      dispatch(dataDontSave());
    }
  };

export const changeApp =
  (type: string, value: string) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, domain } = landing;
    const data = { type, value };

    const response = await Api.saveApplication({
      merchantId,
      projectId,
      domain,
      data,
    });
    if (response?.status !== 200) {
      return;
    }

    const updatedLanding = { ...landing };
    updatedLanding.apps[type] = value;
    dispatch({
      type: UPDATE_LANDING,
      landing: updatedLanding,
    });
  };

export const deleteApp =
  (type: string) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, domain } = landing;
    const data = { type };

    const response = await Api.deleteApplication({
      merchantId,
      projectId,
      domain,
      data,
    });
    if (response?.status !== 200) {
      return;
    }

    const updatedLanding = { ...landing };
    updatedLanding.apps[type] = '';
    dispatch({
      type: UPDATE_LANDING,
      landing: updatedLanding,
    });
  };

export const changeSetting =
  (propName: string, propValue: any) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: { landing },
    } = getState();
    const { merchantId, projectId, _id: landingId } = landing;
    const data = {
      propName,
      propValue,
    };

    const response = await Api.saveLandingValue({
      merchantId,
      projectId,
      landingId,
      data,
    });
    if (response?.status !== 200) {
      return false;
    }
    const updatedLanding = { ...landing };
    updatedLanding[propName] = propValue;
    dispatch({
      type: UPDATE_LANDING,
      landing: { ...updatedLanding },
    });
    return true;
  };

export default function landingReducer(
  state: LandingState = initialState,
  action: Action
) {
  switch (action.type) {
    case FETCH_AVAILABLE_COMPONENTS:
    case ADD_LANGUAGE:
    case DELETE_LANGUAGE:
    case SET_MULTIPAGE_MODE:
    case SET_PREVIEW:
      return { ...state, ...action.payload };
    case INIT_LANDING:
    case UPDATE_LANDING:
      return { ...state, landing: action.landing };
    case LANDING_IS_LOAD:
      return { ...state, loading: action.loading };
    case END_LOADING:
      return { ...state, loading: action.loading, isIframe: action.isIframe };
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale };
    case SET_ERROR:
      return { ...state, loadStatus: action.loadStatus, error: action.error };
    default:
      return state;
  }
}
