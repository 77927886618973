"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameKey = void 0;
const item_1 = require("../univeral-item/types/item");
const universal_item_1 = require("../univeral-item/universal-item");
class GameKey extends universal_item_1.StoreItem {
    constructor(item) {
        super(item);
        this.type = item_1.ItemType.GAME_KEY;
        this.drmSku = item.drm_sku;
        this.drmName = item.drm_name;
        this.isPreOrder = item.is_pre_order;
        this.releaseDate = item.release_date;
        this.isFree = item.is_free;
        this.hasKeys = item.has_keys;
    }
    getParentSku() {
        return this.sku.substring(0, this.sku.length - this.drmSku.length - 1);
    }
}
exports.GameKey = GameKey;
