import {
  VirtualItem,
  VirtualCurrency,
  VirtualCurrencyPack,
  Unit,
  Bundle,
  VirtualItemGroup,
} from '@site-builder/store-sdk';

export const START_FETCH_VIRTUAL_ITEMS = `store/startFetchVirtualItems`;
export const FETCHED_VIRTUAL_ITEMS = `store/fetchedVirtualItems`;
export const START_FETCH_VIRTUAL_CURRENCIES = `store/startFetchVirtualCurrencies`;
export const FETCHED_VIRTUAL_CURRENCIES = `store/fetchedVirtualCurrencies`;
export const START_FETCH_UNITS = `store/startFetchUnits`;
export const FETCHED_UNITS = `store/fetchedUnits`;
export const START_FETCH_BUNDLES = `store/startFetchBundles`;
export const FETCHED_BUNDLES = `store/fetchedBundles`;
export const FETCHED_VIRTUAL_GROUP = `store/fetchedVirtualGroup`;
export const CLEAR_ITEMS = `store/clearItems`;

/**
 * We have to use the "&" operator because the TS returns the "type" property as a string, not as a literal
 */
type StartFetchVirtualItemsAction = ReturnType<
  typeof startFetchVirtualItems
> & {
  type: typeof START_FETCH_VIRTUAL_ITEMS;
};
type FetchedVirtualItemsAction = ReturnType<typeof fetchVirtualItems> & {
  type: typeof FETCHED_VIRTUAL_ITEMS;
};
type StartFetchVirtualCurrenciesAction = {
  type: typeof START_FETCH_VIRTUAL_CURRENCIES;
};
type FetchedVirtualCurrenciesAction = ReturnType<
  typeof fetchVirtualCurrencies
> & { type: typeof FETCHED_VIRTUAL_CURRENCIES };
type StartFetchUnitsAction = {
  type: typeof START_FETCH_UNITS;
};
type FetchedUnitsAction = ReturnType<typeof fetchUnits> & {
  type: typeof FETCHED_UNITS;
};
type StartFetchBundleAction = {
  type: typeof START_FETCH_BUNDLES;
};
type FetchedBundleAction = ReturnType<typeof fetchBundles> & {
  type: typeof FETCHED_BUNDLES;
};
type FetchVirtualGroupAction = ReturnType<typeof fetchVirtualItemsGroup> & {
  type: typeof FETCHED_VIRTUAL_GROUP;
};

type ClearItemsAction = {
  type: typeof CLEAR_ITEMS;
};

export type Action =
  | StartFetchVirtualItemsAction
  | FetchedVirtualItemsAction
  | StartFetchVirtualCurrenciesAction
  | FetchedVirtualCurrenciesAction
  | StartFetchUnitsAction
  | FetchedUnitsAction
  | StartFetchBundleAction
  | FetchedBundleAction
  | FetchVirtualGroupAction
  | ClearItemsAction;

export const clearItems = () => ({
  type: CLEAR_ITEMS,
});

export const startFetchVirtualItems = (groupId: string) => ({
  type: START_FETCH_VIRTUAL_ITEMS,
  payload: {
    groupId,
  },
});

export const fetchVirtualItemsGroup = (groups: VirtualItemGroup[]) => {
  const stateVirtualGroups = groups.reduce(
    (result, group) => ({
      ...result,
      [group.externalId]: {
        ...group,
        loading: false,
      },
    }),
    {}
  );

  return {
    type: FETCHED_VIRTUAL_GROUP,
    payload: {
      virtualItemGroups: stateVirtualGroups,
    },
  };
};

export const fetchVirtualItems = (
  virtualItems: VirtualItem[],
  groupId: string
) => ({
  type: FETCHED_VIRTUAL_ITEMS,
  payload: {
    virtualItems,
    groupId,
  },
});

export const startFetchVirtualCurrencies = () => ({
  type: START_FETCH_VIRTUAL_CURRENCIES,
});
export const fetchVirtualCurrencies = ([
  virtualCurrencies,
  virtualCurrencyPacks,
]: [
  virtualCurrencies: VirtualCurrency[],
  virtualCurrencyPacks: VirtualCurrencyPack[]
]) => ({
  type: FETCHED_VIRTUAL_CURRENCIES,
  payload: {
    virtualCurrencies,
    virtualCurrencyPacks,
  },
});

export const startFetchUnits = () => ({
  type: START_FETCH_UNITS,
});
export const fetchUnits = (units: Unit[]) => ({
  type: FETCHED_UNITS,
  payload: {
    units,
  },
});

export const startFetchBundles = () => ({
  type: START_FETCH_BUNDLES,
});
export const fetchBundles = (bundles: Bundle[]) => ({
  type: FETCHED_BUNDLES,
  payload: {
    bundles,
  },
});
