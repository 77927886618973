"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformGroup = void 0;
const transformGroup = (group) => {
    return {
        externalId: group.external_id,
        name: group.name,
    };
};
exports.transformGroup = transformGroup;
