import { Authorization } from '@site-builder/common/src/types/model/landing';

import { UserIdServiceApi } from '../../../api/user-id-service.api';
import { WEBHOOK_SUB } from '../../../config';
import { CookieNames, setCookie } from '../../../utils/cookie';
import { Environment } from '../../../utils/types';
import { AnalyticsVariables } from '../../afterBlocks/analytics/analytics';
import {
  IUserAccountServiceProps,
  UserAccountService,
} from './user-account-service';

export class UserAccountIdService extends UserAccountService {
  protected readonly webhookUserInfo: any;

  protected readonly authSettings: Authorization;

  protected static instance: UserAccountIdService | null = null;

  protected userIdServiceApi: UserIdServiceApi;

  private readonly environment: Environment;

  constructor({
    projectId,
    merchantId,
    auth,
    environment,
  }: IUserAccountIdServiceProps) {
    super({ projectId, merchantId });
    this.userIdServiceApi = new UserIdServiceApi(environment);
    this.authSettings = auth;
    if (this.tokenData?.payload) {
      this.webhookUserInfo = JSON.parse(this.tokenData.payload).userInfo;
    }
    this.environment = environment;
    AnalyticsVariables.userId = this.userId || '';
  }

  get picture() {
    if (this.tokenData?.type === 'server_custom_id' && this.webhookUserInfo) {
      const { picture } = this.webhookUserInfo;
      return picture ?? super.picture;
    }
    return super.picture;
  }

  get userName() {
    let name = '';
    if (this.tokenData?.type === 'server_custom_id') {
      name = this.getUserNameFromWebhookInfo();
    }
    if (!name) {
      name = super.userName;
    }
    return name;
  }

  get userId(): string | null {
    return (
      this.tokenData?.server_custom_id ||
      this.tokenData?.external_account_id ||
      null
    );
  }

  private getUserNameFromWebhookInfo() {
    if (!this.webhookUserInfo) {
      return '';
    }
    const { id, name } = this.webhookUserInfo;
    if (name) {
      return name;
    }
    if (id) {
      return id;
    }
    return '';
  }

  async authUser(
    userId: string,
    country?: string,
    needUpdateInstance = false
  ): Promise<{ isAuth: boolean; status: number }> {
    const { userIdLoginId } = this.authSettings;
    const { merchantId, projectId } = this.projectInfo;
    const res = await this.userIdServiceApi.authByCustomId({
      settings: {
        projectId: Number(projectId),
        merchantId: Number(merchantId),
      },
      loginId: userIdLoginId,
      webhookUrl: this.getWebhookUrl(),
      user: {
        id: userId,
        country,
      },
    });
    if (res.status === 201) {
      const { token } = res.data;
      setCookie(CookieNames.USER_LOGIN_TOKEN, token);
      if (!needUpdateInstance) {
        window.location.reload();
      } else {
        UserAccountIdService.getInstance(
          {
            ...this.projectInfo,
            auth: this.authSettings,
            environment: this.environment,
          },
          true
        );
      }
    }
    return { isAuth: res.status === 201, status: res.status };
  }

  protected getWebhookUrl() {
    const { webhookUrl, requiredWebhook } = this.authSettings;
    if (!requiredWebhook) {
      return WEBHOOK_SUB;
    }
    return webhookUrl;
  }

  static getInstance(
    { projectId, merchantId, auth, environment }: IUserAccountIdServiceProps,
    needCreate = false
  ) {
    if (!UserAccountIdService.instance || needCreate) {
      UserAccountIdService.instance = new UserAccountIdService({
        projectId,
        merchantId,
        auth,
        environment,
      });
    }
    return UserAccountIdService.instance;
  }
}

export interface IUserAccountIdServiceProps extends IUserAccountServiceProps {
  auth: Authorization;
  environment: Environment;
}
