import { CookieNames } from '../../../utils/cookie';
import { analyticsManager } from '../../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../afterBlocks/analytics/analyticsCategories';
import { xsollaAnalytics } from '../../helpers/analytics';
import { deleteCookie, getOldLoginWidgetTheme } from '../../helpers/common';
import { openModalWindow } from '../../ui-components/editor/modalWindow';
import { getWidgetGenerationInstance } from './generation';
import { Config as FirstGenerationConfig } from './generation/firstGeneration';
import { Config as ZeroGenerationConfig } from './generation/zeroGeneration';

declare global {
  interface Window {
    LOGIN_THEME: any;
  }
}

const LAST_LOGIN_SOURCE_KEY = 'last-login-source';
const SB_LOGIN_THEME =
  'https://cdn.xsolla.net/site-builder/client/xsolla-login.css';
export const LOGIN_SOURCES = {
  CART: 'cart',
  STORE_BUY_BUTTON_REG_EXPR: /^store-item-/,
  STORE_BUY_BUTTON: 'store-item',
  CUSTOM_AMOUNT_BUTTON: /^custom-amount-/,
  HEADER: 'header',
  'SUBSCRIPTIONS-PACKS': 'subscriptions-packs',
  BUY_BUTTON: /^.*-buy-button/,
};

export const LOGIN_WIDGET_ROUTES: {
  LOGIN: 1;
  SIGNUP: 2;
} = {
  LOGIN: 1,
  SIGNUP: 2,
};

export type LoginWidgetRoute = 1 | 2;

export const ATTRIBUTE_TO_ROUTE = {
  login: LOGIN_WIDGET_ROUTES.LOGIN,
  signup: LOGIN_WIDGET_ROUTES.SIGNUP,
};

export const setLastLoginSource = (source): void =>
  localStorage.setItem(LAST_LOGIN_SOURCE_KEY, source);

export const getLastLoginSource = (): string =>
  localStorage.getItem(LAST_LOGIN_SOURCE_KEY) || '';

export const clearLastLoginSource = (): void =>
  localStorage.removeItem(LAST_LOGIN_SOURCE_KEY);

export const isLoginLastSource = (source: string | RegExp) => {
  if (source instanceof RegExp) {
    return source.test(getLastLoginSource());
  }
  return source === getLastLoginSource();
};

export const openLogin = ({
  source,
  route,
  isCustomAuth,
}: {
  source: string | null;
  route?: LoginWidgetRoute;
  isCustomAuth: boolean;
}) => {
  if (!isCustomAuth) {
    const concreteRoute = route || LOGIN_WIDGET_ROUTES.LOGIN;
    if (source) {
      localStorage.setItem(LAST_LOGIN_SOURCE_KEY, source);
    }

    const widgetInstance = getWidgetGenerationInstance();

    if (widgetInstance) {
      widgetInstance.open(concreteRoute);
    }
  }
};

type InitButtonProps = {
  button: HTMLElement;
  source: string | null;
  route?: LoginWidgetRoute;
  isCustomAuth: boolean;
};

export const initLoginButton = ({
  button,
  source,
  route,
  isCustomAuth,
}: InitButtonProps) => {
  button.onclick = (e) => {
    e.stopPropagation();
    openLogin({ source, route, isCustomAuth });
  };
};

export const openLoginWidget = ({
  isCustomAuth,
  loginButtons,
  additionData,
  sourceFinder,
}: {
  isCustomAuth: boolean;
  loginButtons: NodeListOf<HTMLElement> | HTMLElement[];
  sourceFinder?: (HTMLElement) => string;
  additionData?: Record<string, string>;
}) => {
  [...loginButtons].forEach((button) => {
    let source;
    if (sourceFinder) {
      source = sourceFinder(button);
    }
    initLoginButton({ source, ...additionData, button, isCustomAuth });
  });
};

export const logOut = () => {
  xsollaAnalytics((XA) => XA.elementClick('xsolla_login_click_logout'));
  deleteCookie(CookieNames.USER_LOGIN_TOKEN);
  window.location.reload();
};

export const getTheme = async (loginId: string) => {
  const customTheme = await getOldLoginWidgetTheme(loginId);
  // LOGIN_THEME - [SB-1299] тема логина, инициализированная через кастом код, имеет больший приоритет для обратной совместимости
  const theme = window.LOGIN_THEME || customTheme || SB_LOGIN_THEME;
  delete window.LOGIN_THEME;
  return theme;
};

export type ConfigMiddleware = ((
  config: ZeroGenerationConfig
) => ZeroGenerationConfig) &
  ((config: FirstGenerationConfig) => FirstGenerationConfig);
let configMiddleware: ConfigMiddleware = (config: any): any => config;

export const setConfigMiddleware = (middleware: typeof configMiddleware) => {
  configMiddleware = middleware;
};

export const getConfigMiddleware = () => configMiddleware;

export const openUserIdModalForButton = (id?: string) => {
  if (id) {
    setLastLoginSource(id);
  }
  const modal = document.querySelector(`[data-id=${'user-id-modal'}]`);
  openModalWindow(modal, 'UserId');
  analyticsManager.sendEvent({
    category: AnalyticsCategories.STORE,
    event: AnalyticsActions.OPEN_UID_WIDGET_FROM_BUY_NOW,
    page: `landing_${AnalyticsActions.OPEN_UID_WIDGET}`,
  });
};
