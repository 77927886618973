// @flow
import cn from 'classnames';
import React from 'react';

import './style.pcss';

type Props = {|
  className?: string,
  onClick?: Function,
  onFocus?: Function,
  children?: React$Node,
  onMouseEnter?: Function,
  onMouseLeave?: Function,
  disabled?: boolean,
  customType?: string,
  type?: string,
  outline?: boolean,
  isEnabledShadow?: boolean,
  isLoading?: boolean,
  hidden?: boolean,
  dataAttributes?: Object
|}

export const OldButton = ({
  className,
  onClick,
  onFocus,
  children,
  disabled,
  customType,
  type,
  onMouseEnter,
  onMouseLeave,
  outline,
  isEnabledShadow,
  isLoading,
  hidden,
  dataAttributes
}: Props) => (
  <button
    className={cn(
      'simple-button',
      { 'simple-button--loading': isLoading },
      { 'simple-button--outline': outline },
      { 'simple-button--with-shadow': isEnabledShadow },
      className
    )}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    disabled={disabled}
    onClick={onClick}
    data-type={customType}
    type={type}
    onFocus={onFocus}
    hidden={hidden}
    {...dataAttributes}
  >
    <div className='simple-button__preloader' />
    <div className='simple-button__text'>
      {children}
    </div>
  </button>
);

OldButton.defaultProps = {
  className: '',
  onClick: () => {},
  onFocus: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  children: null,
  disabled: false,
  customType: '',
  type: 'button',
  outline: false,
  isEnabledShadow: false,
  isLoading: false,
  hidden: false,
  dataAttributes: {}
};
