import axios, { AxiosResponse } from 'axios';

import requestErrorHandler from '../utils/request-error-handler';
import { Environment } from '../utils/types';
import { getUserIdServiceURL } from '../utils/url-helper';

export class UserIdServiceApi {
  constructor(private environment: Environment) {}

  authByCustomId(
    data: AuthByUserIdBodyDto
  ): Promise<AxiosResponse<IAuthResponse>> {
    return requestErrorHandler(
      axios({
        baseURL: getUserIdServiceURL(this.environment),
        url: `/user-id`,
        method: 'post',
        data,
      })
    );
  }

  updateSocialTokenByUserId(
    data: UpdateSocialTokenByUserIdBodyDto
  ): Promise<AxiosResponse<IAuthResponse>> {
    return requestErrorHandler(
      axios({
        baseURL: getUserIdServiceURL(this.environment),
        url: `/user-id/social`,
        method: 'post',
        data,
      })
    );
  }
}

interface AuthByUserIdBodyDto {
  loginId: string;
  webhookUrl: string;
  settings: {
    projectId: number;
    merchantId: number;
  };
  user: {
    id: string;
    country?: string;
  };
}

interface UpdateSocialTokenByUserIdBodyDto extends AuthByUserIdBodyDto {
  token: string;
}

interface IAuthResponse {
  token: string;
}
