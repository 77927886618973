"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VirtualItemGroup = void 0;
class VirtualItemGroup {
    constructor(item) {
        this.externalId = item.external_id;
        this.name = item.name;
        this.description = item.description;
        this.imageUrl = item.image_url;
        this.level = item.level;
        this.order = item.order;
        this.parentExternalId = item.parent_external_id;
        this.children = item.children.map((item) => new VirtualItemGroup(item));
    }
}
exports.VirtualItemGroup = VirtualItemGroup;
