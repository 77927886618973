/** @prettier */
/* eslint-disable camelcase */
import { SUBSCRIPTIONS_CLIENT_API, SUBSCRIPTIONS_API } from '../../config';
import { ScriptsState } from '../../reducers/types/scripts-state';
import { scrollToElement } from '../../utils/common-helper';
import { openPayStationWidget } from '../../utils/paystation/open-widget';
import { getPayStationTokenForBBWidget } from '../../utils/paystation/paystation-token';
import { generateSubscriptionButtonText } from '../../utils/render-subscriptions';
import { payStationPostMessage } from '../afterBlocks/analytics/payStationPostMessages';
import { initLoginButton } from '../blocks/user-account-services';
import events from '../events';
import { getTokenFromCookie, setUserAttribute } from '../helpers/common';
import { getLoginScript } from '../helpers/thirdPartyScripts';
import { translations } from '../translations';
import { openModalWindow } from '../ui-components/editor/modalWindow';
import { subscriptionsService } from './subscriptions';

// Получение списка подписочных планов
const getSubscriptionsPlans = async (projectId) => {
  const subscriptionsPlans = await fetch(
    `${SUBSCRIPTIONS_CLIENT_API}/v1/plans?project_id=${Number(projectId)}`,
    {
      method: 'GET',
    }
  );

  return subscriptionsPlans.json();
};

// get subscription products. TODO: change to using SubscriptionsPacks/utils/api.js after SSS-1551
const getSubscriptionsProducts = async (projectId) => {
  const response = await fetch(
    `${SUBSCRIPTIONS_API}/api/public/v1/projects/${Number(projectId)}/products`,
    {
      method: 'GET',
    }
  );
  const subscriptionsProducts = await response.json();
  return subscriptionsProducts?.items.reduce(
    (acc, item) => ({
      ...acc,
      [item.product_group_id]: item.product_id.toString(),
    }),
    {}
  );
};

const setSubscriptionButtonText = ({ button, locale, subscription }) => {
  const buttonText = generateSubscriptionButtonText(subscription, locale);
  const textNode = button.querySelector('.ui-site-calltoaction__text');
  textNode.innerHTML = buttonText;
};

// Получение postMessages от PS
const getPayStationPostMessages = ({
  userToken,
  subscription,
  button,
  data,
}: {
  data: ScriptsState,
}) => {
  const { projectId, isPreviewFrameMode, merchantId } = data;
  const { block } = JSON.parse(button.getAttribute('data-xa-extra'));
  payStationPostMessage.block = block;
  payStationPostMessage.addHandler('buySubscription', async (psData) => {
    if (psData.action === 'open-paystation') {
      document
        .querySelectorAll('.ui-site-calltoaction__text')
        .forEach((textNode) => {
          textNode.classList.remove('is-preloader');
        });
    }

    if (psData.value === 'done' || psData.state === 'usersubscription') {
      await subscriptionsService.init({
        isPreviewFrameMode,
        projectId,
      });
      // eslint-disable-next-line no-use-before-define
      await checkUserSubscriptionsStatus({
        subscription,
        userToken,
        button,
        translations,
        data,
      }); // eslint-disable-line
    }

    if (psData.state === 'status' && psData.value === 'done') {
      sessionStorage.removeItem('emptyList');
      sessionStorage.removeItem('fullList');
      sessionStorage.setItem('isSubscriptionPurchase', '1');
      const userData = {
        attributes: [
          {
            key: 'subscription_purchase',
            permission: 'public',
            value: '1',
          },
        ],
        publisher_project_id: Number(projectId),
        merchantId: Number(merchantId),
      };
      await setUserAttribute(userToken, userData);
    }
  });

  window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, () => {
    if (sessionStorage.getItem('isSubscriptionPurchase') === '1') {
      const thankYouModalWindow = document.querySelector(
        '.ui-site-thank-you-modal'
      );
      openModalWindow(thankYouModalWindow, 'ThankYou');
      sessionStorage.clear();
    }
  });
};

const initPayStationWidget = ({
  token,
  userToken,
  button,
  subscription,
  data,
}: {
  data: ScriptsState,
}) => {
  openPayStationWidget({
    token,
    renderMode: data.renderMode,
  });

  getPayStationPostMessages({
    userToken,
    subscription,
    button,
    translations,
    data,
  });
};

const initPayStationSubscriptionManageMode = async ({
  button,
  subscription,
  userToken,
  data,
}: {
  data: ScriptsState,
}) => {
  const {
    merchantId,
    projectId,
    isPreviewFrameMode,
    renderMode,
    locale,
    analyticsCounterId,
    payStationReturnUrl,
  } = data;

  const token = await getPayStationTokenForBBWidget({
    merchantId,
    projectId,
    isPreviewFrameMode,
    userToken,
    renderMode,
    locale,
    analyticsCounterId,
    payStationReturnUrl,
    ui: {
      mode: 'user_account',
      user_account: {
        subscriptions: {
          order: 1,
          enable: true,
        },
      },
    },
  });

  initPayStationWidget({
    token,
    userToken,
    translations,
    button,
    subscription,
    data,
  });
};

export const activeSubscriptionsFlow = ({
  subscription,
  button,
  userToken,
  data,
}: {
  data: ScriptsState,
}) => {
  button.classList.add('ui-site-calltoaction--subscription-current');
  button.style.backgroundColor = 'transparent';
  button.style.color = 'currentColor';
  const buttonParent = button.parentNode;

  const configurationButton = buttonParent.querySelector(
    '[data-subscription-configuration-btn]'
  );
  const configurationText = configurationButton.querySelector(
    '.ui-site-calltoaction__text'
  );
  configurationText.innerText =
    translations['client.subscription.button.configuration'];

  const textNode = button.querySelector('.ui-site-calltoaction__text');
  textNode.classList.remove('is-preloader');
  textNode.innerText = translations['client.subscription.button.current.text'];
  configurationButton.classList.remove('hidden');

  configurationButton.onclick = () => {
    configurationText.classList.add('is-preloader');
    initPayStationSubscriptionManageMode({
      translations,
      button,
      subscription,
      userToken,
      data,
    });
  };
};

export class SubscriptionLoaderService implements ILoaderService {
  _sessionId = '';

  _isCurrentButton = false;

  constructor(button) {
    this._sessionId = sessionStorage.getItem('data-external-id') || '';
    const buttonFromSession =
      this._sessionId &&
      document.querySelector(`[data-external-id=${this._sessionId}]`);
    this._isCurrentButton = !!(
      button &&
      buttonFromSession &&
      buttonFromSession.id === button.id
    );
  }

  checkAttach() {
    return !!this._sessionId;
  }

  checkDetach() {
    return this._isCurrentButton;
  }

  detach() {
    const button = document.querySelector(
      `[data-external-id=${this._sessionId}]`
    );
    if (button) {
      scrollToElement(button, { block: 'center' });
      button.click();
    }
    this._sessionId = '';
    sessionStorage.clear();
  }
}

export const noActiveSubscriptionFlow = async ({
  subscription,
  button,
  userToken,
  data,
}: {
  data: ScriptsState,
}) => {
  const configurationButton = button.parentNode.querySelector(
    '[data-subscription-configuration-btn]'
  );
  const savedExternalId = sessionStorage.getItem('data-external-id');

  const {
    merchantId,
    projectId,
    isPreviewFrameMode,
    renderMode,
    locale,
    analyticsCounterId,
    payStationReturnUrl,
  } = data;

  const getToken = () =>
    getPayStationTokenForBBWidget({
      merchantId,
      projectId,
      isPreviewFrameMode,
      userToken,
      renderMode,
      locale,
      analyticsCounterId,
      payStationReturnUrl,
      purchase: {
        subscription: {
          plan_id: subscription.external_id,
        },
      },
    });

  if (savedExternalId === subscription.external_id) {
    scrollToElement(button, { block: 'center' });
    initPayStationWidget({
      token: await getToken(),
      userToken,
      translations,
      button,
      subscription,
      data,
    });
    sessionStorage.clear();
  }

  if (!subscription) {
    return;
  }

  const textNode = button.querySelector('.ui-site-calltoaction__text');
  button.classList.remove('ui-site-calltoaction--subscription-current');
  textNode.classList.remove('is-preloader');
  configurationButton.classList.add('hidden');
  setSubscriptionButtonText({
    subscription,
    button,
    locale,
  });
  button.onclick = async () => {
    initPayStationWidget({
      token: await getToken(),
      userToken,
      translations,
      button,
      subscription,
      data,
    });
  };
};

export function checkUserSubscriptionsStatus({
  subscription,
  userToken,
  button,
  data,
}: {
  data: ScriptsState,
}) {
  const activeSubscription = subscriptionsService.userSubscriptionsInfo.find(
    ({ plan_external_id }) => plan_external_id === subscription.external_id
  );
  const activeSubscriptionId =
    activeSubscription && activeSubscription.status != null
      ? activeSubscription.plan_external_id
      : '';
  if (subscription) {
    const { external_id } = subscription;
    const params = {
      subscription,
      button,
      translations,
      userToken,
      data,
    };
    return activeSubscriptionId === external_id
      ? activeSubscriptionsFlow(params)
      : noActiveSubscriptionFlow(params);
  }

  const textNode = button.querySelector('.ui-site-calltoaction__text');
  return textNode.classList.remove('is-preloader');
}

const renderSubscription = (() => {
  let allSubscriptionPromise = null;
  let subscriptionProductPromise = null;
  let isSessionCleanSet = false;
  const setSessionCleanFunction = (XL) => {
    if (isSessionCleanSet) {
      return;
    }

    XL.on(XL.eventTypes.HIDE_POPUP, () => {
      // eslint-disable-line
      sessionStorage.clear();
    });
    isSessionCleanSet = true;
  };

  return async ({ target, data }: { data: ScriptsState, target: any }) => {
    const button = document.querySelector(target);
    if (!target || !button) {
      return;
    }
    if (!allSubscriptionPromise) {
      allSubscriptionPromise = getSubscriptionsPlans(data.projectId);
      subscriptionProductPromise = getSubscriptionsProducts(data.projectId);
    }

    const allSubscriptions = await allSubscriptionPromise;
    const allProducts = await subscriptionProductPromise;
    const userToken = getTokenFromCookie(data.isPreviewFrameMode);

    const externalId = button.getAttribute('data-external-id');
    const subscription = allSubscriptions.find(
      (status) => status.external_id === externalId
    );
    subscription.product_id = allProducts[subscription.external_id];
    if (!subscription) {
      return;
    }
    setSubscriptionButtonText({
      subscription,
      button,
      locale: data.locale,
    });

    if (userToken && allSubscriptions.length > 0) {
      button.classList.remove('xl_auth');

      await checkUserSubscriptionsStatus({
        subscription,
        userToken,
        button,
        translations,
        data,
      });
    } else {
      // Флоу неавторизованного пользователя
      const textNode = button.querySelector('.ui-site-calltoaction__text');
      if (textNode) {
        textNode.classList.remove('is-preloader');
      }

      if (data.isPreviewFrameMode) {
        return;
      }

      button.addEventListener('click', (e) => {
        sessionStorage.setItem(
          'data-external-id',
          e.currentTarget.getAttribute('data-external-id')
        );
      });
      const { XL } = await getLoginScript();
      initLoginButton({ button });
      setSessionCleanFunction(XL);
    }
  };
})();

export const initSubscriptionEventHandler = () => {
  window.addEventListener(
    events.subscription.init,
    (event) => {
      const { target, data, translations } = event.detail;
      renderSubscription({
        data,
        target,
        translations,
      });
    },
    false
  );
};

export const handleSubscription = ({ target, data }) => {
  window.dispatchEvent(
    new CustomEvent(events.subscription.init, {
      detail: {
        target,
        data,
      },
    })
  );
};
