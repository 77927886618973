import { LandingType, StoreItem } from '@site-builder/common/src/utils/store';

import { isItemsForVirtualCurrencyEnabled } from '../../scripts/landingAPI/enableItemsForVirtualCurrency';

export class VirtualGoodItem extends StoreItem {
  _landingType;

  constructor(o, landingType) {
    super(o);
    this._landingType = landingType;
  }

  canShow(): boolean {
    if (this.price || this.isFree) {
      return true;
    }

    const hasVirtualPrices = this.virtual_prices.length > 0;

    if (
      this._landingType !== LandingType.TOPUP ||
      isItemsForVirtualCurrencyEnabled()
    ) {
      return hasVirtualPrices;
    }

    return false;
  }
}
