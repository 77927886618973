/**
 * @prettier
 * @flow
 * */

import type {
  InventoryItem,
  StoreInventoryResponse,
} from '../../../../utils/types';

import { getCurrentUserInventory } from '../api';

export class InventoryService {
  _items: InventoryItem[];

  _token: string;

  _projectId: string;

  _listeners: (() => void)[] = [];

  static _refineApiResults(data: StoreInventoryResponse) {
    return data.items.map<InventoryItem>(
      ({ sku, quantity, virtual_item_type: virtualItemType }) => ({
        sku,
        quantity,
        virtualItemType,
      })
    );
  }

  constructor(token: string, projectId: string) {
    this._items = [];
    this._token = token;
    this._projectId = projectId;
  }

  set items(value: InventoryItem[]) {
    this._items = value;
  }

  async load() {
    this.items = InventoryService._refineApiResults(
      await getCurrentUserInventory(this._token, this._projectId)
    );
    this.__notify();
  }

  getItem(sku: string): InventoryItem | typeof undefined {
    return this._items.find(({ sku: _sku }) => _sku === sku);
  }

  addListener(fn: () => void) {
    this._listeners.push(fn);
  }

  __notify() {
    this._listeners.forEach((listener) => listener());
  }
}
