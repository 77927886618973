"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VirtualCurrencyPack = void 0;
const bundle_1 = require("../bundle/types/bundle");
const item_1 = require("../univeral-item/types/item");
const universal_item_1 = require("../univeral-item/universal-item");
const pack_content_1 = require("./pack-content");
const virtual_currency_1 = require("./virtual-currency");
class VirtualCurrencyPack extends universal_item_1.StoreItem {
    constructor(item) {
        super(item);
        this.type = item_1.ItemType.BUNDLE;
        this.bundleType = bundle_1.BundleType.VIRTUAL_CURRENCY_PACK;
        this.content = item.content.map((item) => new pack_content_1.VirtualCurrencyPackContent(new virtual_currency_1.VirtualCurrency(item), item.quantity));
    }
}
exports.VirtualCurrencyPack = VirtualCurrencyPack;
