import {
  Bundle,
  Unit,
  VirtualCurrency,
  VirtualCurrencyPack,
  VirtualItem,
  VirtualItemGroup,
} from '@site-builder/store-sdk';

import {
  START_FETCH_VIRTUAL_ITEMS,
  START_FETCH_VIRTUAL_CURRENCIES,
  START_FETCH_UNITS,
  START_FETCH_BUNDLES,
  FETCHED_VIRTUAL_ITEMS,
  FETCHED_VIRTUAL_CURRENCIES,
  FETCHED_UNITS,
  FETCHED_BUNDLES,
  FETCHED_VIRTUAL_GROUP,
  Action,
  CLEAR_ITEMS,
} from './actions';
import { distinctVirtualItems } from './helpers';

export interface StoreItemState<T> {
  items: T;
  loading: boolean;
}
export interface StoreState {
  virtualItemGroups: {
    items: Record<string, VirtualItemGroup & { loading: boolean }>;
    fetched: boolean;
  };
  virtualItems: StoreItemState<VirtualItem[]>;
  virtualCurrencies: StoreItemState<VirtualCurrency[]>;
  virtualCurrencyPacks: StoreItemState<VirtualCurrencyPack[]>;
  units: StoreItemState<Unit[]>;
  bundles: StoreItemState<Bundle[]>;
}

const initialState: StoreState = {
  virtualItemGroups: {
    items: {},
    fetched: false, // Load virtual items group once, we shouldn't t load again if a user adds another one store block
  },
  virtualItems: {
    items: [],
    loading: false,
  },
  virtualCurrencies: {
    items: [],
    loading: false,
  },
  virtualCurrencyPacks: {
    items: [],
    loading: false,
  },
  units: {
    items: [],
    loading: false,
  },
  bundles: {
    items: [],
    loading: false,
  },
};

export const storeReducer = (
  state: StoreState = initialState,
  action: Action
) => {
  switch (action.type) {
    case FETCHED_VIRTUAL_GROUP:
      return {
        ...state,
        virtualItemGroups: {
          items: action.payload.virtualItemGroups,
          fetched: true,
        },
      };
    case START_FETCH_VIRTUAL_ITEMS:
      return {
        ...state,
        virtualItems: {
          items: state.virtualItems.items,
        },
        virtualItemGroups: {
          ...state.virtualItemGroups,
          items: {
            ...state.virtualItemGroups.items,
            [action.payload.groupId]: {
              ...state.virtualItemGroups.items[action.payload.groupId],
              loading: true,
            },
          },
        },
      };
    case FETCHED_VIRTUAL_ITEMS:
      return {
        ...state,
        virtualItems: {
          items: distinctVirtualItems(
            state.virtualItems.items,
            action.payload.virtualItems
          ),
        },
        virtualItemGroups: {
          ...state.virtualItemGroups,
          items: {
            ...state.virtualItemGroups.items,
            [action.payload.groupId]: {
              ...state.virtualItemGroups.items[action.payload.groupId],
              loading: false,
            },
          },
        },
      };
    case START_FETCH_VIRTUAL_CURRENCIES:
      return {
        ...state,
        virtualCurrencies: {
          items: state.virtualCurrencies.items,
          loading: true,
        },
        virtualCurrencyPacks: {
          items: state.virtualCurrencyPacks.items,
          loading: true,
        },
      };
    case FETCHED_VIRTUAL_CURRENCIES:
      return {
        ...state,
        virtualCurrencies: {
          items: action.payload.virtualCurrencies,
          loading: false,
        },
        virtualCurrencyPacks: {
          items: action.payload.virtualCurrencyPacks,
          loading: false,
        },
      };
    case START_FETCH_UNITS:
      return {
        ...state,
        units: {
          items: state.units.items,
          loading: false,
        },
      };
    case FETCHED_UNITS:
      return {
        ...state,
        units: {
          items: action.payload.units,
          loading: false,
        },
      };
    case START_FETCH_BUNDLES:
      return {
        ...state,
        bundles: {
          items: state.bundles.items,
          loading: true,
        },
      };
    case FETCHED_BUNDLES:
      return {
        ...state,
        bundles: {
          items: action.payload.bundles,
          loading: false,
        },
      };
    case CLEAR_ITEMS:
      return {
        ...state,
        virtualItems: {
          items: [],
          loading: false,
        },
        virtualCurrencies: {
          items: [],
          loading: false,
        },
        virtualCurrencyPacks: {
          items: [],
          loading: false,
        },
        units: {
          items: [],
          loading: false,
        },
        bundles: {
          items: [],
          loading: false,
        },
      };
    default:
      return state;
  }
};
