import { getPreviewLabels } from './helpers';

export const getFreeItemButtonText = getPreviewLabels('client.free_item.get');
export const getFreeItemButtonReceivedText = getPreviewLabels(
  'client.free_item.received'
);
export const getFreeItemModalButtonText = getPreviewLabels(
  'client.free_item.modal.button.back_to_store'
);
export const getFreeItemModalSuccessTitle = getPreviewLabels(
  'client.free_item.modal.success.title'
);
export const getFreeItemModalErrorTitle = getPreviewLabels(
  'client.free_item.modal.error.title'
);
export const getFreeItemModalErrorDescription = getPreviewLabels(
  'client.free_item.modal.error.description'
);
