import { StoreWebSocket } from '@xsolla/store-websocket';

import { UserAccountIdService } from '../../user-account-services/user-account-id-service';
import { UserAccountService } from '../../user-account-services/user-account-service';

export class StoreReloadService {
  private reloadIgnoreOrderIds: number[] = [];

  private listeners: (() => void)[] = [];

  constructor({
    userService,
    projectId,
  }: {
    userService: UserAccountService;
    projectId: string;
  }) {
    if (
      userService.isAuthorized &&
      userService instanceof UserAccountIdService
    ) {
      const socket = new StoreWebSocket({
        user_external_id: userService.userId,
        project_id: Number(projectId),
      });
      socket.onerror = () => {
        socket.close();
      };
      socket.onopen = () => {
        // To prevent the directory from being reloaded, from old events
        setTimeout(() => {
          socket.onmessage = (data: {
            // eslint-disable-next-line camelcase
            order_id: number;
            status: string;
          }) => {
            if (
              data.status === 'done' &&
              this.checkNotIgnoreId(data.order_id)
            ) {
              this.callListeners();
            }
          };
        }, 1000);
      };
      socket.connect();
    }
  }

  addListener(listener: () => void) {
    this.listeners.push(listener);
  }

  addReloadIgnoreOrderIds(orderId: number) {
    this.reloadIgnoreOrderIds.push(orderId);
  }

  private checkNotIgnoreId(orderId: number) {
    return !this.reloadIgnoreOrderIds.includes(orderId);
  }

  private callListeners() {
    this.listeners.forEach((listener) => {
      listener();
    });
  }
}
