"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromStoreApiToStoreItem = void 0;
const bundle_1 = require("./bundle/bundle");
const bundle_2 = require("./bundle/types/bundle");
const game_key_1 = require("./game-key/game-key");
const unit_1 = require("./unit/unit");
const item_1 = require("./univeral-item/types/item");
const pack_1 = require("./virtual-currency/pack");
const virtual_currency_1 = require("./virtual-currency/virtual-currency");
const virtual_item_1 = require("./virtual-item/virtual-item");
function fromStoreApiToStoreItem(item) {
    switch (item.type) {
        case item_1.ItemType.UNIT:
            return new unit_1.Unit(item);
        case item_1.ItemType.GAME_KEY:
            return new game_key_1.GameKey(item);
        case item_1.ItemType.VIRTUAL_CURRENCY:
            return new virtual_currency_1.VirtualCurrency(item);
        case item_1.ItemType.BUNDLE:
            if (item.bundle_type === bundle_2.BundleType.STANDARD)
                return new bundle_1.Bundle(item);
            return new pack_1.VirtualCurrencyPack(item);
        case item_1.ItemType.VIRTUAL_GOOD:
            return new virtual_item_1.VirtualItem(item);
        default:
            throw new Error('Unknown store item type');
    }
}
exports.fromStoreApiToStoreItem = fromStoreApiToStoreItem;
