"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemType = void 0;
var ItemType;
(function (ItemType) {
    ItemType["BUNDLE"] = "bundle";
    ItemType["GAME_KEY"] = "game_key";
    ItemType["PHYSICAL_GOOD"] = "physical_good";
    ItemType["UNIT"] = "unit";
    ItemType["VIRTUAL_CURRENCY"] = "virtual_currency";
    ItemType["VIRTUAL_GOOD"] = "virtual_good";
})(ItemType = exports.ItemType || (exports.ItemType = {}));
