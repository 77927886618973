import type { LocalizedValue } from '../../value/localized-value';
import type { AbstractComponent } from '../common/abstract-component';

import { Asset } from '../../model/asset';
import { ComponentTypes } from '../common/component-types';
import { Alignment } from './new-store-common';

export enum StoreItemType {
  BUNDLE = 'bundle',
  UNIT = 'unit',
  VIRTUAL_CURRENCY = 'virtual_currency',
  VIRTUAL_GOOD = 'virtual_good',
}

export interface NewSectionItem {
  type: StoreItemType;
  group?: string;
}

export enum StoreCardLayoutType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type StoreCardImageSize = 'contain' | 'cover' | 'auto';

export type NewStoreLayoutsType = {
  [K in StoreCardLayoutType]: NewStoreCardLayout;
};

export interface NewStoreCardLayout {
  alignment: Alignment;
  image: {
    size: StoreCardImageSize; // default 'contain'
    format: 'horizontal' | 'square' | 'vertical'; // default 'square'
    background: boolean;
  };
  itemsDescriptionEnabled: boolean;
  priceInButton: boolean;
}

export interface NewStoreSection {
  item: NewSectionItem;
  title: LocalizedValue;
  horizontalScroll: boolean;
  background: Asset;
}

export interface NewStoreCard {
  selectedLayoutType: StoreCardLayoutType;
  layouts: NewStoreLayoutsType;
}

export interface NewStoreComponent extends AbstractComponent {
  type: ComponentTypes.NEW_STORE_SECTION;
  section: NewStoreSection;
  card: NewStoreCard;
}
