/**
 * @prettier
 * @flow
 * */

import { fiveSymbolsMap } from '@site-builder/common/src/types/locale';

import type {
  AnyStoreItemType,
  CartItemObjectExtends,
  GameKeysType,
} from '../../../../utils/types';
import type { Classed } from '../types';
import type {
  StoreItemIdentifier,
  StoreItemType,
  StoreVirtualCurrency,
  StoreVirtualItem,
} from '@site-builder/common/src/flow-types/store';
import type {
  TwoSymbolLocale,
  FiveSymbolLocale,
} from '@site-builder/common/src/types/locale';
import type { LandingTypes } from '@site-builder/common/src/types/model/landing';
import type { StoreBundle } from '@site-builder/common/src/types/store';

import {
  getBundleBySKU,
  getGameKeyBySKU,
  getVirtualCurrencyPackBySKU,
  getVirtualItemBySKU,
} from '../../../../utils/api';
import {
  checkAvailableDRM,
  isShowGame,
  transformFieldsFromStoreToCart,
} from '../../../../utils/store-helper';
import { storeItemFactory } from '../../../../utils/store/store-item-factory';
import {
  getGameKeys,
  getBundles,
  getVirtualCurrencies,
  getVirtualCurrencyPacks,
  getVirtualItems,
  getUuid,
} from '../api';
import { CartItem } from '../cart/CartItem';
import { cartItemFactory } from '../cart/cartItemFactory';
import { GameKeyCartItem } from '../cart/GameKeyCartItem';
import { VICartItem } from '../cart/VICartItem';

export class StoreAPIDataService {
  _isAuth: boolean;

  _cartId: string;

  _token: string;

  _projectId: string;

  _locale: TwoSymbolLocale;

  _virtualItems: Classed<StoreVirtualItem>[] = [];

  _virtualCurrencies: Classed<StoreVirtualCurrency>[] = [];

  _virtualCurrencyPacks: Classed<StoreBundle>[] = [];

  _bundles: Classed<StoreBundle>[] = [];

  _gameKeys: Classed<GameKeysType>[] = [];

  _landingType: ?LandingTypes;

  static instance: StoreAPIDataService | null = null;

  constructor(
    cartId: string,
    token: ?string,
    projectId: string,
    locale: FiveSymbolLocale
  ) {
    this._isAuth = !!token; // user is authorized if we get JWT
    this._cartId = cartId;
    this._token = token || getUuid(cartId);
    this._projectId = projectId;
    this._locale = fiveSymbolsMap[`${locale}`];
  }

  static getInstance(
    cartId: string,
    token: ?string,
    projectId: string,
    locale: FiveSymbolLocale
  ) {
    if (!StoreAPIDataService.instance) {
      StoreAPIDataService.instance = new StoreAPIDataService(
        cartId,
        token,
        projectId,
        locale
      );
    }
    return StoreAPIDataService.instance;
  }

  get token() {
    return this._token;
  }

  get virtualItems() {
    return this._virtualItems;
  }

  get bundles() {
    return this._bundles;
  }

  get virtualCurrencies() {
    return this._virtualCurrencies;
  }

  get virtualCurrencyPacks() {
    return this._virtualCurrencyPacks;
  }

  get gameKeys() {
    return this._gameKeys;
  }

  get allVirtualGoods() {
    return [
      ...this._virtualItems,
      ...this._virtualCurrencies,
      ...this._virtualCurrencyPacks,
      ...this._gameKeys,
      ...this._bundles,
    ];
  }

  get landingType() {
    return this._landingType;
  }

  setLandingType(landingType: LandingTypes) {
    this._landingType = landingType;
  }

  findBySku(sku: $PropertyType<StoreItemType, 'sku'>) {
    const foundGK = this.findGKByDRM(sku);
    if (foundGK) {
      return foundGK;
    }
    return this.allVirtualGoods.find((item) => item.sku === sku);
  }

  findGKByDRM(
    sku: $PropertyType<GameKeysType, 'sku'>
  ): Classed<GameKeysType> | void {
    // eslint-disable-next-line camelcase
    return this._gameKeys.find(({ unit_items }) =>
      unit_items.find((unit) => unit.sku === sku)
    );
  }

  static checkAvailableDRM(item: GameKeysType): boolean {
    return !!item.unit_items.find(isShowGame);
  }

  static filterByGroup(
    items: Classed<StoreVirtualItem>[],
    groupId: string
  ): Classed<StoreVirtualItem>[] {
    /* eslint-disable camelcase */
    return items.filter(({ groups }) =>
      groups.some(({ external_id }) => external_id === groupId)
    );
    /* eslint-enable */
  }

  async loadVirtualItems(
    groupId: string
  ): Promise<Classed<StoreVirtualItem>[]> {
    let newVirtualItems = [];
    try {
      newVirtualItems = await getVirtualItems(
        this._projectId,
        groupId,
        this._locale,
        this.token,
        this._isAuth
      );
    } catch (e) {
      if (e.statusCode !== 404) {
        throw e;
      }
    }
    const uniqueNewVirtualItems = newVirtualItems.filter(
      (newItem) => !this._virtualItems.find((item) => newItem.sku === item.sku)
    );
    this._virtualItems = [
      ...this._virtualItems,
      ...uniqueNewVirtualItems.map(storeItemFactory(this._landingType)),
    ];
    return StoreAPIDataService.filterByGroup(this._virtualItems, groupId);
  }

  async loadVirtualCurrencies(): Promise<Classed<StoreVirtualCurrency>[]> {
    const newVirtualCurrencies = await getVirtualCurrencies(
      this._projectId,
      this._locale,
      this.token,
      this._isAuth
    );
    const uniqueVirtualCurrencies = newVirtualCurrencies.filter(
      (newCurrency) =>
        !this._virtualCurrencies.find(
          (currency) => newCurrency.sku === currency.sku
        )
    );
    this._virtualCurrencies = [
      ...this._virtualCurrencies,
      ...uniqueVirtualCurrencies.map(storeItemFactory(this._landingType)),
    ];
    return this._virtualCurrencies;
  }

  loadBundles = async () => {
    this._bundles = (
      await getBundles(this._projectId, this._locale, this.token, this._isAuth)
    ).map(storeItemFactory(this._landingType));
    return this._bundles;
  };

  async loadVirtualCurrencyPacks() {
    const newVirtualCurrencyPacks = await getVirtualCurrencyPacks(
      this._projectId,
      this._locale,
      this.token,
      this._isAuth
    );
    const uniqueVirtualCurrencyPacks = newVirtualCurrencyPacks.filter(
      (newCurrencyPack) =>
        !this._virtualCurrencyPacks.find(
          (currencyPack) => newCurrencyPack.sku === currencyPack.sku
        )
    );
    this._virtualCurrencyPacks = [
      ...this._virtualCurrencyPacks,
      ...uniqueVirtualCurrencyPacks.map(storeItemFactory(this._landingType)),
    ];
    return this._virtualCurrencyPacks;
  }

  loadGameKeys = async () => {
    if (this._gameKeys.length) {
      return this._gameKeys;
    }
    const newGameKeys = await getGameKeys(
      this._projectId,
      this._locale,
      this.token,
      this._isAuth
    );
    const uniqueGameKeys = newGameKeys
      .filter(
        (newGameKey) =>
          !this._gameKeys.find((gameKey) => gameKey.isSame(newGameKey)) &&
          checkAvailableDRM(newGameKey)
      )
      .map((newGameKey) => {
        newGameKey.unit_items = newGameKey.unit_items.filter(isShowGame);
        return newGameKey;
      });
    this._gameKeys = [
      ...this._gameKeys,
      ...uniqueGameKeys.map(storeItemFactory(this._landingType)),
    ];
    return this._gameKeys;
  };

  _loadGameKeyBySKU = async (sku: string) => {
    const newGameKey = await getGameKeyBySKU(
      this._projectId,
      this._locale,
      sku
    );
    if (
      newGameKey &&
      this._gameKeys.every((gameKey) => !gameKey.isSame(newGameKey))
    ) {
      // $FlowFixMe
      this._gameKeys = [
        ...this._gameKeys,
        storeItemFactory(this._landingType)(newGameKey),
      ];
    }
  };

  _loadBundleBySKU = async (sku: string) => {
    const newBundle = await getBundleBySKU(this._projectId, this._locale, sku);
    if (
      newBundle &&
      this._bundles.every((bundleItem) => !bundleItem.isSame(newBundle))
    ) {
      // $FlowFixMe
      this._bundles = [
        ...this._bundles,
        storeItemFactory(this._landingType)(newBundle),
      ];
    }
  };

  _loadVirtualItemBySKU = async (sku: string) => {
    const newVirtualItem = await getVirtualItemBySKU(
      this._projectId,
      this._locale,
      sku
    );
    if (
      newVirtualItem &&
      this._virtualItems.every((VIItem) => !VIItem.isSame(newVirtualItem))
    ) {
      // $FlowFixMe
      this._virtualItems = [
        ...this._virtualItems,
        storeItemFactory(this._landingType)(newVirtualItem),
      ];
    }
  };

  _loadVirtualCurrencyPackBySKU = async (sku: string) => {
    const newVirtualCurrencyPack = await getVirtualCurrencyPackBySKU(
      this._projectId,
      this._locale,
      sku
    );
    if (
      newVirtualCurrencyPack &&
      this._virtualCurrencyPacks.every(
        (VCPack) => !VCPack.isSame(newVirtualCurrencyPack)
      )
    ) {
      this._virtualCurrencyPacks = [
        ...this._virtualCurrencyPacks,
        // $FlowFixMe
        storeItemFactory(this._landingType)(newVirtualCurrencyPack),
      ];
    }
  };

  loadLackItems = async (
    cartItems: Array<
      GameKeyCartItem | VICartItem | CartItem | StoreItemIdentifier
    >
  ) => {
    // $FlowFixMe
    const itemsToLoad = cartItems.filter<
      GameKeyCartItem | VICartItem | CartItem | StoreItemIdentifier
    >((item) => {
      if (item.selectedDRM) {
        return !this.findGKByDRM(item.selectedDRM);
      }
      return !this.findBySku(item.sku);
    });

    const loadItems = [];
    itemsToLoad.forEach((item) => {
      const type = item.bundleType ? item.bundleType : item.type;
      switch (type) {
        case 'standard':
        case 'bundle':
          loadItems.push(this._loadBundleBySKU(item.sku));
          break;
        case 'unit': // LocaleStorage
        case 'game_key': // API
          loadItems.push(this._loadGameKeyBySKU(item.sku));
          break;
        case 'virtual_good':
          loadItems.push(this._loadVirtualItemBySKU(item.sku));
          break;
        case 'virtual_currency_package':
          loadItems.push(this._loadVirtualCurrencyPackBySKU(item.sku));
          break;
        default:
          break;
      }
    });
    await Promise.all(loadItems);
  };

  // $FlowFixMe
  filterNoExistItems = (cartItems: $ReadOnly<CartItemObjectExtends[]>) =>
    cartItems.filter<CartItem>((item) => {
      // $FlowFixMe
      const data: AnyStoreItemType = this.findBySku(item.sku);
      if (!data) {
        return null;
      }
      const itemWithCamelCase = transformFieldsFromStoreToCart({
        ...data,
        selectedDRM: item.selectedDRM,
        quantity: item.quantity,
        price: item.price,
      });
      return cartItemFactory(itemWithCamelCase);
    });
}
