import cn from 'classnames';
import React, { ReactElement } from 'react';

import { TextSize, FieldName } from './types';

interface WrapperProps {
  size?: TextSize; // if size isn't passed, we're looking at inner tags (h1, h2, h3...)
  field: FieldName;
  style?: React.CSSProperties;
  innerHTML: { __html: string } | undefined;
  className?: string;
  dataId: string;
  children?: ReactElement;
}

export const Wrapper = ({
  style,
  className,
  field,
  size,
  innerHTML,
  children,
  dataId,
}: WrapperProps) => (
  <div
    className={cn(
      'ui-site-description',
      `ui-site-description--${field}`,
      { [`ui-site-description--${String(size)}`]: !!size },
      className
    )}
    data-id={dataId}
    dangerouslySetInnerHTML={innerHTML} // eslint-disable-line
    style={style}
  >
    {children}
  </div>
);
