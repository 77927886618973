/**
 * @flow
 * @prettier
 */
import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';

import type { ScriptCommonArgs, ScriptsData, Translations } from '../types';

type BlockModuleComponent = { default: Function };

// Variables in path not allowed because, Webpack will bundle all assets in a directory
export const initBlock = (
  props: ScriptCommonArgs
): Promise<BlockModuleComponent> => {
  switch (props.blockName) {
    case 'embed':
      return import(/* webpackChunkName: "embeds" */ './embeds');
    case 'faq':
      return import(/* webpackChunkName: "faq" */ './faq');
    case 'gallery':
      return import(/* webpackChunkName: "gallery" */ './gallery');
    case 'promoSlider':
      return import(/* webpackChunkName: "promoSlider" */ './promoSlider');
    case BlockModule.STORE:
      return import(/* webpackChunkName: "store" */ './store');
    case BlockModule.NEW_STORE:
      return import(/* webpackChunkName: "new-store" */ './newStore');
    case 'header':
      return import(/* webpackChunkName: "header" */ './header');
    case 'hero':
      return import(/* webpackChunkName: "hero" */ './hero');
    case 'packs':
      return import(/* webpackChunkName: "packs" */ './packs');
    case 'subscriptions-packs':
      return import(
        /* webpackChunkName: "subscriptions-packs" */ './subscriptions-packs'
      );
    case 'news':
      return import(/* webpackChunkName: "news" */ './news');
    case 'requirements':
      return import(/* webpackChunkName: "requirements" */ './requirements');
    case 'footer':
      return import(/* webpackChunkName: "footer" */ './footer');
    case 'retailers':
      return import(/* webpackChunkName: "retailers" */ './retailers');
    default:
      return Promise.resolve({ default: () => {} });
  }
};

export const commonBlocksScripts = async (
  scriptsState: ScriptsData,
  translations: Translations
) => {
  const BLOCKS_SELECTOR = '.block';

  const blockList = [...document.querySelectorAll(BLOCKS_SELECTOR)];
  await Promise.all(
    blockList.map(async (block) => {
      /**
       * Instead of using simple split('-'), I've used RegEx, because simple splitting
       * cause some issues with rendering blocks, ig:
       * header-62139b3594fa5eafbc8d1c18 = ['header', 'id'] ✅
       * subscriptions-packs-62139b5194fa5eafbc8d1c6f = ['subscriptions', 'packs', 'id'] ❌
       * footer-62139b3594fa5eafbc8d1c23 = ['footer', 'id'] ✅
       *
       * For basic operations with block.id RegEx /-(?!.*-)/ is practically safe, ig:
       * header-62139b3594fa5eafbc8d1c18 = ['header', 'id'] ✅
       * subscriptions-packs-62139b5194fa5eafbc8d1c6f = ['subscriptions-packs', 'id'] ✅
       * footer-62139b3594fa5eafbc8d1c23 = ['footer', 'id'] ✅
       *
       * But in case if block.id is not trusted and may contains namings errors, ig:
       * -62139b3594fa5eafbc8d1c18 = ['', 'id']
       * --62139b3594fa5eafbc8d1c18 = ['-', 'id']
       *
       * NOTE: used Negative Lookahead (?!<any>) which is safe for all JS engines
       */
      const [blockName, blockId] = block.id.toString().split(/-(?!.*-)/);
      const params: ScriptCommonArgs = {
        block,
        blockName,
        blockStructure:
          scriptsState.blocksStructures.find(
            (block) => block._id === blockId
          ) || null,
        blockId,
        data: scriptsState,
        translations,
      };
      await initBlock(params).then((module) => module.default(params));
    })
  );
};
