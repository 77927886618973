"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformVirtualPrice = exports.transformPrice = void 0;
const item_1 = require("../univeral-item/types/item");
const transformPrice = (price) => {
    return {
        amount: price.amount,
        amountWithoutDiscount: price.amount_without_discount,
        currency: price.currency,
    };
};
exports.transformPrice = transformPrice;
const transformVirtualPrice = (price) => {
    return {
        amount: price.amount,
        amountWithoutDiscount: price.amount_without_discount,
        calculatedPrice: {
            amount: price.calculated_price.amount,
            amountWithoutDiscount: price.calculated_price.amount_without_discount,
        },
        description: price.description,
        imageUrl: price.image_url,
        isDefault: price.is_default,
        name: price.name,
        sku: price.sku,
        type: item_1.ItemType.VIRTUAL_CURRENCY,
    };
};
exports.transformVirtualPrice = transformVirtualPrice;
