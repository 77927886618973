// compilerOptions.moduleResolution: "node16" in tsconfig.json only requires *.js path for dynamic import
/* eslint-disable import/extensions */

export const reactQuillLoader = () => import('react-quilljs');
export const reactPopperTooltipLoader = () => import('react-popper-tooltip');
export const stimulusLoader = () =>
  // compilerOptions.moduleResolution: "node16" in tsconfig.json only requires *.js path for dynamic import
  // @ts-ignore
  import('../../scripts/stimulus/stimulus.ts');

export const openPayStationWidgetLoader = () =>
  // compilerOptions.moduleResolution: "node16" in tsconfig.json only requires *.js path for dynamic import
  // @ts-ignore
  import('../paystation/open-widget.ts');
