import { BundleItem, StoreItem, UnitDRMItem } from '@site-builder/common/src/utils/store';

import { VirtualGoodItem } from './VirtualGoodItem';

export const storeItemFactory = (landingType) => (storeItem) => {
  if (StoreItem.isDRM(storeItem)) {
    return new UnitDRMItem(storeItem);
  }

  if (StoreItem.isBundle(storeItem)) {
    return new BundleItem(storeItem, landingType);
  }

  if (StoreItem.isVirtualGood(storeItem)) {
    return new VirtualGoodItem(storeItem, landingType);
  }

  return new StoreItem(storeItem);
};
