import { getPreviewLabels } from './helpers';

export const getXsollaLogoPoweredText = getPreviewLabels(
  'client.footer.xsollalogo.powered'
);
export const getXsollaLogoSbText = getPreviewLabels(
  'client.footer.xsollalogo.sb'
);
export const getSubscribersListButtonText = getPreviewLabels(
  'client.footer.button.list_of_subscribers'
);
