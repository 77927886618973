import { initPartnersRequests } from '../../experiments/partnersRequests/utils';
import { fetchUsedStoreSections } from '../../reducers/modules/site-builder/store/action-creators';
import { initLazy } from './lazyLoad';
import relativeLinks from './relativeLinks';
import { saveSearchParams } from './urlSearchParams';
import { checkYoutubeThumbnail } from './youtubeThumbnail';

export const beforeBlocksScripts = async (store) => {
  const { scripts: scriptsState } = store.getState();

  const needRedirect = await saveSearchParams(scriptsState);
  store.dispatch(fetchUsedStoreSections());

  checkYoutubeThumbnail(scriptsState);
  relativeLinks(scriptsState);
  initPartnersRequests(scriptsState);
  initLazy();
  return needRedirect;
};
