const STORAGE_VARIABLES_NAME = 'sb-free-item-data';

export const FreeItemStorage = {
  setData({
    parentId,
    buttonId,
    sku,
  }: {
    buttonId: string;
    parentId: string;
    sku: string;
  }) {
    localStorage.setItem(
      STORAGE_VARIABLES_NAME,
      JSON.stringify({ buttonId, parentId, sku })
    );
  },

  getData(): {
    parentId: string;
    buttonId: string;
    sku: string;
  } | null {
    const json = localStorage.getItem(STORAGE_VARIABLES_NAME);
    if (!json) {
      return null;
    }
    return JSON.parse(json);
  },

  removeData() {
    localStorage.removeItem(STORAGE_VARIABLES_NAME);
  },
};
