import { getPreviewLabels } from './helpers';

export const getShowMoreText = getPreviewLabels('client.store.item.show_more');

export const getStoreCheckoutText = getPreviewLabels(
  'client.buy_button.checkout'
);

export const getStoreBuyButtonText = getPreviewLabels(
  'blocks.store.buy_button.text'
);
export const getAddToCartText = getPreviewLabels(
  'client.store.cart.add_button'
);
export const getStoreMoreThan10GameKeysErrorText = getPreviewLabels(
  'client.store.error.gt10_gk'
);
export const getStoreNoGameKeysErrorText = getPreviewLabels(
  'client.store.error.no_keys'
);
export const getStoreСheckoutText = getPreviewLabels(
  'client.buy_button.checkout'
);
export const getPreOrderText = getPreviewLabels('client.buy_button.pre-order');

export const getBuyButtonText = getPreviewLabels('client.buy_button.text');

export const getNoItemsText = getPreviewLabels('client.store.no_items');

export const getHeaderStoreButtonText = getPreviewLabels(
  'client.store.cart.header_button'
);

export const getBundleModalGroupText = getPreviewLabels(
  'client.bundle_modal.group_items'
);
