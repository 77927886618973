import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';

import { XSOLLA_ANALYTICS_SERVER } from '../../../config';
import { parseMerchantToNumber } from '../../../utils/common-helper';
import { Listener, Subscribers } from '../../helpers/subscribers';
import { ScriptsData } from '../../types';
import { AnalyticsActions } from './analyticsActions';
import { AnalyticsCategories } from './analyticsCategories';
import {
  EventForGA,
  EventForXsollaMetrika,
  XsollaMetrikaEventNames,
} from './analyticsEventsList';

// @ts-ignore

export const Props = {
  VALUE: 'xa-value',
};

export enum MethodsEnum {
  ELEMENT_CLICK = 'elementClick',
}

enum GetEventNameTypes {
  ANALYTICS = 'analytics',
  METRIKA = 'metrika',
}

type IGetEventName = {
  event: AnalyticsActions | XsollaMetrikaEventNames;
  type: GetEventNameTypes;
};

const IsAnalyticsActions = (event: string): event is AnalyticsActions => {
  return Object.values(AnalyticsActions).includes(event as AnalyticsActions);
};

const IsXsollaMetrikaEventNames = (
  event: string
): event is XsollaMetrikaEventNames => {
  return Object.values(XsollaMetrikaEventNames).includes(
    event as XsollaMetrikaEventNames
  );
};

export const AnalyticsVariables = {
  userId: '',
};

const getEventName = ({ event, type }: IGetEventName): string => {
  if (type === GetEventNameTypes.ANALYTICS) {
    return (
      EventForGA[event as keyof typeof EventForGA] ||
      (IsAnalyticsActions(event) ? event : '')
    );
  }
  if (type === GetEventNameTypes.METRIKA) {
    return (
      EventForXsollaMetrika[event as keyof typeof EventForXsollaMetrika] ||
      (IsXsollaMetrikaEventNames(event) ? event : '')
    );
  }
  return '';
};

export type AnalyticsEvent = {
  category: AnalyticsCategories;
  event: AnalyticsActions | XsollaMetrikaEventNames;
  block?: BlockModule;
  label?: string;
  page?: string;
  section?: 'pop-up';
  userId?: string | null;
  value?: any | null;
};

const analyticsManager: Subscribers<
  Listener<AnalyticsEvent>,
  AnalyticsEvent
> = new Subscribers<Listener<AnalyticsEvent>, AnalyticsEvent>();
export { analyticsManager };

class XsollaMetrika implements Listener<AnalyticsEvent> {
  XA: any;

  constructor(XA: any) {
    this.XA = XA;
  }

  sendEvent({
    category,
    event,
    block,
    label,
    page,
    section,
  }: AnalyticsEvent): void {
    const metrikaEvent = getEventName({
      event,
      type: GetEventNameTypes.METRIKA,
    });
    if (this.XA && metrikaEvent) {
      this.XA.elementClick(metrikaEvent, {
        ev: label,
        exv: {
          block,
          category,
          page,
          section,
          userId: AnalyticsVariables.userId,
        },
      });
    }
  }
}

declare global {
  interface Window {
    gtag: any;
  }
}

export class GoogleAnalytics implements Listener<AnalyticsEvent> {
  partnerGA: string;

  constructor(partnerGA: string) {
    this.partnerGA = partnerGA;
  }

  sendEvent({ category, event, page, label }: AnalyticsEvent) {
    const analyticsEvent = getEventName({
      event,
      type: GetEventNameTypes.ANALYTICS,
    });
    if (analyticsEvent) {
      window.gtag('event', analyticsEvent, {
        event_category: category,
        event_label: label,
        send_to: this.partnerGA,
      });
      if (page) {
        window.gtag('event', 'page_view', {
          page_path: page,
          page_title: page,
          send_to: this.partnerGA,
        });
      }
    }
  }
}

export class DataLayer implements Listener<AnalyticsEvent> {
  dataLayer: any[];

  constructor(dataLayer: any[]) {
    this.dataLayer = dataLayer;
  }

  sendEvent({ category, event, label, block, page, value }: AnalyticsEvent) {
    const dataLayerEvent = getEventName({
      event,
      type: GetEventNameTypes.ANALYTICS,
    });
    if (dataLayerEvent) {
      this.dataLayer.push({
        category,
        event: dataLayerEvent,
        label,
        block,
        page,
        value,
        userId: AnalyticsVariables.userId,
      });
    }
  }
}

export class FacebookPixel implements Listener<AnalyticsEvent> {
  private fbq: (
    eventType: 'track' | 'trackCustom',
    name: string,
    data?: Record<string, unknown>
  ) => void;

  constructor(fbq: any) {
    this.fbq = fbq;
  }

  sendEvent({
    category,
    event,
    label,
    block,
    page,
    value,
  }: AnalyticsEvent): void {
    this.fbq('trackCustom', event, {
      category,
      label,
      block,
      url: page,
      ...value,
    });
  }
}

export const sendEvent = ({
  name,
  value,
  extra,
}: {
  name: string;
  value?: string;
  extra?: string;
}) => {
  const { XA } = window;
  if (!XA) {
    return;
  }

  XA.elementClick(name, {
    ev: value,
    exv: extra,
  });
};

export const submitEvent = (element: HTMLElement) => {
  const { XA } = window;
  if (!XA) {
    return;
  }
  const { xaMethod } = element.dataset;

  switch (xaMethod) {
    case MethodsEnum.ELEMENT_CLICK: {
      const { xaName, xaValue, xaExtra } = element.dataset;

      XA.elementClick(xaName, {
        ev: xaValue,
        exv: xaExtra,
      });
      break;
    }
    default:
      break;
  }
};

/**
 * Should attach xsolla analytics all child elements whose have attribute data-xa-method
 * @param parentElement
 * @type {HTMLElement | Document}
 */
export const attachAnalytics = (parentElement: HTMLElement | Document) => {
  const targetElementsArray =
    parentElement.querySelectorAll('[data-xa-method]');
  targetElementsArray.forEach((element) => {
    element.addEventListener('click', () => {
      submitEvent(element as HTMLElement);
    });
  });
};

export const analytics = (data: ScriptsData) => {
  const { merchantId, analyticsCounterId } = data;
  const { hostname } = window.location;
  if (!merchantId || !analyticsCounterId || hostname === 'localhost') {
    return;
  }
  const { XsollaAnalytics } = window;
  const XA = new XsollaAnalytics({
    id: analyticsCounterId,
    server: XSOLLA_ANALYTICS_SERVER,
    merchantId: parseMerchantToNumber(merchantId),
    siteDomains: hostname,
  });
  window.XA = XA;

  const XALoad = new Event('XALoad') as Event & { XA: any };
  XALoad.XA = XA;
  document.dispatchEvent(XALoad);

  attachAnalytics(document);
  analyticsManager.subscribe(new XsollaMetrika(XA));
};
