import { getPreviewLabels } from './helpers';

export const getInstructionStepText = getPreviewLabels(
  'document.pages.auth.instruction.steps.description'
);

export const getInputPlaceholderTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-placeholder'
);
export const getButtonGoBackTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.go-back'
);
export const getSocialNameTextForUserIdModal = (socialName: string) =>
  getPreviewLabels(`client.user-id-modal.socials.${socialName}`);

export const getContunueWithSociaTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.continue-with'
);

export const getInputPasteButtonTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-paste-button'
);
export const getInputIncorrectTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-incorrect-id'
);
export const getInputIncorrectCopyPasteTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.input-incorrect-id-copy-and-paste'
);
export const getItemPurchasedText = getPreviewLabels(
  'client.store.item.purchased'
);
export const getContinueButtonTextForUserIdModal = getPreviewLabels(
  'client.user-id-modal.continue-button'
);
