"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreItem = void 0;
const attributes_1 = require("../attributes");
const groups_1 = require("../groups");
const price_1 = require("../price");
class StoreItem {
    constructor(item) {
        this.sku = item.sku;
        this.name = item.name;
        this.type = item.type;
        this.description = item?.description;
        this.isFree = item.is_free;
        this.imageUrl = item.image_url;
        this.price = item.price ? (0, price_1.transformPrice)(item.price) : null;
        this.virtualPrices = item.virtual_prices
            ? item.virtual_prices.map(price_1.transformVirtualPrice)
            : null;
        this.attributes = item.attributes
            ? item.attributes.map(attributes_1.transformAttribute)
            : [];
        this.canBeBought = 'can_be_bought' in item ? item.can_be_bought : true;
        this.groups = item.groups ? item.groups.map(groups_1.transformGroup) : [];
    }
    get isAbleToBuyForRealPrice() {
        return this.price !== null;
    }
    get hasVirtualPrice() {
        return !!(this.virtualPrices && this.virtualPrices.length !== 0);
    }
    getVirtualPrice() {
        if (this.virtualPrices) {
            return (this.virtualPrices?.find((price) => price.isDefault) ||
                this.virtualPrices[0]);
        }
        return null;
    }
}
exports.StoreItem = StoreItem;
