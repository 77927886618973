/** @prettier */
// @flow
import { RenderMode } from '@site-builder/common/src/types/build';
import cn from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';

import type { ImageValue } from '@site-builder/common/src/flow-types/values';
import type { Asset } from '@site-builder/common/src/types/model/asset';
import type { intlShape } from 'react-intl';

import { getStyleForLazyLoad } from '../../../utils/lazy-load-img';
import { getVideoThumbnail } from '../../../utils/video';
import Image from '../Image';
import VideoPlayer from '../VideoPlayer';
import YouTubeVideo from '../YouTubeVideo';

import './style.pcss';

type Props = {|
  intl: intlShape,
  asset: ImageValue | Asset,
  onMediaButtonClick: Function,
  renderMode: RenderMode,
  isMainImage?: boolean,
  className?: string,
  isShowButton?: boolean,
  canSetVideo?: boolean,
  videoSettings?: Object,
  openAssets?: boolean,
  showCustomBackground?: boolean,
  options?: Object,
  mediaType?: 'background' | 'logo',
  disableLazyLoad?: boolean,
|};

const _Media = ({
  asset,
  intl,
  onMediaButtonClick,
  renderMode,
  className = '',
  isMainImage = true,
  isShowButton = true,
  canSetVideo,
  videoSettings = {},
  openAssets,
  showCustomBackground = true,
  options,
  mediaType,
  disableLazyLoad = false,
}: Props) => {
  const bgVideoImage: string =
    asset.type === 'video' && asset.video ? getVideoThumbnail(asset.video) : '';

  const lazyLoadStyles = (img) =>
    getStyleForLazyLoad(renderMode !== RenderMode.EDITING, img, {
      backgroundSize: asset.size,
      // $FlowFixMe
      backgroundAttachment: asset?.backgroundAttachment,
    });

  const backgroundForVideo = videoSettings.isBackgroundVideo && (
    <>
      <div
        className='ui-site-media__background-color'
        style={{ backgroundColor: asset.color }}
      />
      <div
        className='ui-site-media__image ui-site-media__image--video-preview lazy'
        data-bg={`url(${bgVideoImage})`}
        data-size={asset.size}
        style={lazyLoadStyles(bgVideoImage)}
      />
    </>
  );

  return (
    <div
      className={cn(
        'ui-site-media',
        className,
        { 'main-image': isMainImage },
        { 'ui-site-media__video': asset.type === 'video' },
        {
          'ui-site-media__video--as-background':
            videoSettings.isBackgroundVideo && asset.type === 'video',
        }
      )}
    >
      {asset.type === 'video' && asset.player !== 'steam' && (
        <>
          <YouTubeVideo
            videoSettings={{ ...videoSettings, videoUrl: asset.video }}
          />
          {backgroundForVideo}
        </>
      )}
      {asset.type === 'video' &&
        asset.player === 'steam' &&
        renderMode !== RenderMode.EDITING && (
          <>
            <VideoPlayer video={asset.video} options={options || {}} />
            {backgroundForVideo}
          </>
        )}
      {asset.type === 'video' &&
        asset.player === 'steam' &&
        renderMode === RenderMode.EDITING && (
          <div className='ui-site-media__video-wrappaer'>
            <img
              className='ui-site-media__video-thumbnail'
              src={asset.img}
              alt='Video'
            />
            <i className='material-icons play-icon'>play_circle_filled</i>
          </div>
        )}
      {asset.type !== 'video' && mediaType !== 'logo' && showCustomBackground && (
        <>
          <div
            className='ui-site-media__background-color'
            style={{ backgroundColor: asset.color }}
          />
          <div
            className={cn('ui-site-media__image', { lazy: !disableLazyLoad })}
            data-bg={`url(${asset.img})`}
            data-size={asset.size}
            style={getStyleForLazyLoad(
              renderMode !== RenderMode.EDITING && !disableLazyLoad,
              asset.img,
              {
                backgroundSize: asset.size,
              }
            )}
          />
        </>
      )}
      {asset.type !== 'video' && mediaType === 'logo' && (
        <>
          <div
            className='ui-site-media__background-color'
            style={{ backgroundColor: asset.color }}
          />
          {asset.img && <Image imageLink={asset.img} size={asset.size} />}
        </>
      )}
      {renderMode === RenderMode.EDITING && (
        <div
          className='ui-site-media__button-wrapper'
          role='button'
          tabIndex={0}
          onClick={isMainImage ? onMediaButtonClick : () => {}} // TODO: mark onMediaButtonClick as nullable
          onKeyDown={() => {}}
        >
          {isShowButton &&
            (isMainImage ? (
              <button
                type='button'
                className='ui-site-media__main-image-assets'
                onClick={onMediaButtonClick}
              >
                {canSetVideo
                  ? intl.messages['nav_block.settings.image.set_media']
                  : intl.messages['nav_block.settings.image.set_image']}
              </button>
            ) : (
              <button
                type='button'
                className={cn('ui-site-media__image-assets', {
                  'open-assets': openAssets,
                })}
                onClick={onMediaButtonClick}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export const Media = injectIntl(_Media);
