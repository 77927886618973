"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformAttribute = void 0;
const transformAttributeValue = (attributeValue) => {
    return {
        externalId: attributeValue.external_id,
        value: attributeValue.value,
    };
};
const transformAttribute = (attribute) => {
    return {
        externalId: attribute.external_id,
        name: attribute.name,
        values: attribute.values.map(transformAttributeValue),
    };
};
exports.transformAttribute = transformAttribute;
