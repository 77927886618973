"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VirtualCurrency = void 0;
const inventory_1 = require("../inventory");
const item_1 = require("../univeral-item/types/item");
const universal_item_1 = require("../univeral-item/universal-item");
class VirtualCurrency extends universal_item_1.StoreItem {
    constructor(item) {
        super(item);
        this.type = item_1.ItemType.VIRTUAL_CURRENCY;
        this.inventoryOptions = item.inventory_options
            ? (0, inventory_1.transformInventoryOptions)(item.inventory_options)
            : null;
    }
}
exports.VirtualCurrency = VirtualCurrency;
