import { BlockModule } from '@site-builder/common/src/types/block/common/block-module';
import { NewStoreBlock } from '@site-builder/common/src/types/block/new-store/new-store-block';
import {
  NewSectionItem,
  StoreItemType,
} from '@site-builder/common/src/types/block/new-store/new-store-component';
import { RenderMode } from '@site-builder/common/src/types/build';
import {
  FiveSymbolLocale,
  fiveSymbolsMap,
  TwoSymbolLocale,
} from '@site-builder/common/src/types/locale';
import { AuthorizationTypes } from '@site-builder/common/src/types/model/landing';
import { StoreDataService } from '@site-builder/store-sdk';

import { isCustomAuth } from '../../../../experiments/partnersRequests/utils';
import {
  openLogin,
  openUserIdModalForButton,
} from '../../../../scripts/blocks/user-account-services';
import { getPaystationWidgetUISettings } from '../../../../scripts/landingAPI/payStationWidgetSettings';
import { LoggerFactory } from '../../../../utils/logger';
import { openPayStationWidgetLoader } from '../../../../utils/module-loader/loaders';
import {
  getTokenSettings,
  getTokenSettingsForOrder,
} from '../../../../utils/paystation/paystation-token';
import { FreeItemStorage } from '../../../../utils/store/free-item';
import { Dispatch, GetState } from '../../../types';
import { RootState } from '../../../types/root-state';
import { clearItems, fetchVirtualItemsGroup } from './actions';
import { constructAuthCredentials, fetchStoreSectionFactory } from './helpers';

const logger = LoggerFactory.getLogger('NewStoreAction');

export const fetchStoreSectionByType =
  (sectionItem: NewSectionItem, fiveSymbolsLocale?: FiveSymbolLocale) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const {
      landing: {
        landing: { projectId },
        locale: currentFiveSymbolsLocale,
        renderMode,
      },
      store: { virtualCurrencies, virtualItems },
    } = getState();
    const auth = constructAuthCredentials(renderMode);

    const locale = fiveSymbolsMap[
      String(fiveSymbolsLocale || currentFiveSymbolsLocale)
    ] as TwoSymbolLocale;

    switch (sectionItem.type) {
      case StoreItemType.VIRTUAL_GOOD:
        {
          const isTargetVirtualItemGroupAlreadyFetched =
            virtualItems.items.some(({ groups }) =>
              groups.find((group) => group.externalId === sectionItem.group)
            );
          if (isTargetVirtualItemGroupAlreadyFetched) {
            return;
          }
        }
        break;
      case StoreItemType.VIRTUAL_CURRENCY:
        if (virtualCurrencies.items.length > 0) return;
        break;
      default:
        break;
    }

    const actionWithLoadedStoreItem = fetchStoreSectionFactory({
      sectionItem,
      projectId,
      auth,
      locale,
    });
    await dispatch(actionWithLoadedStoreItem);
  };

export const fetchUsedStoreSections =
  (fiveSymbolsLocale?: FiveSymbolLocale) =>
  async (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { blocks: blocksState } = getState();

    const storeBlocks = blocksState.blocks.filter(
      (block) => block.module === BlockModule.NEW_STORE
    ) as NewStoreBlock[];
    const nonUniqueSectionItems = storeBlocks.reduce<NewSectionItem[]>(
      (items, storeBlock) => {
        const blockItems = storeBlock.components.map(
          (component) => component.section.item
        );
        return items.concat(blockItems);
      },
      []
    );

    const sectionItems = [
      ...new Map(
        nonUniqueSectionItems.map((item) => {
          const isVirtualItem = item.type === StoreItemType.VIRTUAL_GOOD;
          const mapKey = isVirtualItem
            ? `${item.type}-${item.group}`
            : item.type;
          return [mapKey, item];
        })
      ).values(),
    ];
    if (sectionItems.length < 1) {
      return;
    }
    dispatch(clearItems());
    const {
      landing: {
        landing: { projectId },
        locale: currentFiveSymbolsLocale,
        renderMode,
      },
      store: { virtualItemGroups },
    } = getState();

    const locale = fiveSymbolsMap[
      String(fiveSymbolsLocale || currentFiveSymbolsLocale)
    ] as TwoSymbolLocale;
    const auth = constructAuthCredentials(renderMode);

    // we need info about group only in selector in constructor
    if (renderMode === RenderMode.EDITING) {
      if (!virtualItemGroups.fetched)
        dispatch(
          fetchVirtualItemsGroup(
            await StoreDataService.getVirtualItemsGroup({
              projectId,
            })
          )
        );
    }

    await Promise.allSettled(
      sectionItems.map((sectionItem) => {
        const fetchStoreSectionActionCreator = fetchStoreSectionFactory({
          sectionItem,
          projectId,
          auth,
          locale,
        });
        return dispatch(fetchStoreSectionActionCreator);
      })
    );
  };

const openAuth = ({
  authType,
  buttonId,
}: {
  authType: AuthorizationTypes;
  buttonId: string;
}) => {
  if (authType === AuthorizationTypes.USER_ID) {
    openUserIdModalForButton(buttonId);
  } else {
    openLogin({
      source: buttonId,
      isCustomAuth: isCustomAuth(),
    });
  }
};

export const makePurchaseWithSpecifiedItem =
  (itemSku: string, buttonId: string, setLoading: (value: boolean) => void) =>
  async (_: Dispatch, getState: GetState<RootState>) => {
    const {
      landingServices: { userService },
      landing: {
        landing: { projectId },
        locale,
        renderMode,
      },
      pages: {
        pages: [
          {
            auth: { type: authType },
          },
        ],
      },
    } = getState();

    if (!userService.isAuthorized) {
      openAuth({ authType, buttonId });
      return;
    }
    setLoading(true);
    try {
      const body = getTokenSettingsForOrder({
        settings: getTokenSettings({
          userToken: userService?.userToken,
          projectId,
          payStationReturnUrl: '',
          locale,
          renderMode,
          ui: { ...getPaystationWidgetUISettings() },
        }),
        renderMode,
        quantity: 1,
      });
      const auth = constructAuthCredentials(renderMode);
      const { token } = await StoreDataService.createOrderWithSpecifiedItem({
        projectId,
        itemSku,
        auth,
        body,
      });
      if (token) {
        await openPayStationWidgetLoader().then(({ openPayStationWidget }) => {
          openPayStationWidget({
            token,
            renderMode,
            language: locale,
          });
        });
      }
    } catch (error: any) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

export const buyFreeItem =
  ({
    itemSku,
    setSuccess,
    setLoading,
    buttonId,
    parentId,
  }: {
    itemSku: string;
    buttonId: string;
    parentId: string;
    setLoading: (value: boolean) => void;
    setSuccess: () => void;
  }) =>
  async (_: Dispatch, getState: GetState<RootState>) => {
    const {
      landingServices: { userService, storeReloadService },
      landing: {
        landing: { projectId },
      },
      pages: {
        pages: [
          {
            auth: { type: authType },
          },
        ],
      },
    } = getState();

    if (!userService.isAuthorized) {
      openAuth({ authType, buttonId });
      FreeItemStorage.setData({ parentId, buttonId, sku: itemSku });
      return;
    }

    setLoading(true);
    try {
      const res = await StoreDataService.buyFreeItem({
        projectId,
        itemSku,
        userToken: userService.userToken,
      });
      if (res?.order_id) {
        storeReloadService.addReloadIgnoreOrderIds(res.order_id);
        setSuccess();
      }
    } catch (error: any) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };
