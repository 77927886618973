import { getPreviewLabels } from './helpers';

export const getCommonSubscriptionButtonText = getPreviewLabels(
  'subscription.button.text'
);
export const getLifetimeSubscriptionButtonText = getPreviewLabels(
  'client.subscription.button.lifetime_text'
);

export const getSubscriptionsPacksButtonRenewNow = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.renew.now'
);
export const getSubscriptionsPacksButtonManagePlan = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.manage.plan'
);
export const getSubscriptionsPacksSelectYourPlan = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.select.plan'
);
export const getSubscriptionsPacksNextRenewal = getPreviewLabels(
  'editor.subscriptions-packs.manage.subscriptions.next.renewal'
);
export const getSubscriptionsPacksButtonConfigureSubscription =
  getPreviewLabels(
    'editor.subscriptions-packs.manage.subscriptions.configure.subscription'
  );

export const getSubscriptionsTranslation = ({
  translationName,
  locale,
  options,
}) => getPreviewLabels(translationName)(locale, options);
