import { Reducer } from 'redux';

import { UniversalCart } from '../../../scripts/blocks/store/cart/UniversalCart';
import { InventoryService } from '../../../scripts/blocks/store/services/InventoryService';
import { StoreReloadService } from '../../../scripts/blocks/store/store-reload-service/store-reload-service';
import { UserAccountService } from '../../../scripts/blocks/user-account-services/user-account-service';

const INIT_SERVICES = 'INIT_SERVICES';
const UPDATE_CART = 'UPDATE_CART';

// TODO improve types
export interface LandingServicesState {
  cart: UniversalCart;
  storeAPIDataService: unknown;
  inventory: InventoryService;
  customAmountService: unknown;
  userService: UserAccountService;
  storeReloadService: StoreReloadService;
}
interface Action {
  type: typeof INIT_SERVICES | typeof UPDATE_CART;
  payload: Partial<LandingServicesState>;
}

export const initServices = (services: LandingServicesState) => ({
  type: INIT_SERVICES,
  payload: services,
});

export const updateCart = (cart: UniversalCart) => ({
  type: UPDATE_CART,
  payload: {
    cart,
  },
});

export const landingServices: Reducer<LandingServicesState, Action> = (
  state = Object.create({}),
  action
) => {
  switch (action.type) {
    case INIT_SERVICES:
    case UPDATE_CART:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
