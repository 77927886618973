import {
  NewSectionItem,
  StoreItemType,
} from '@site-builder/common/src/types/block/new-store/new-store-component';
import { RenderMode } from '@site-builder/common/src/types/build';
import { TwoSymbolLocale } from '@site-builder/common/src/types/locale';
import {
  VirtualItem,
  StoreApiAuth,
  StoreDataService,
} from '@site-builder/store-sdk';
import { v4 as uuid } from 'uuid';

import { CookieNames, getCookie } from '../../../../utils/cookie';
import { Dispatch } from '../../../types';
import {
  startFetchVirtualItems,
  startFetchVirtualCurrencies,
  startFetchUnits,
  startFetchBundles,
  fetchVirtualItems,
  fetchVirtualCurrencies,
  fetchBundles,
  fetchUnits,
} from './actions';

export const fetchStoreSectionFactory = ({
  sectionItem,
  projectId,
  auth,
  locale,
}: {
  sectionItem: NewSectionItem;
  projectId: string;
  auth: StoreApiAuth;
  locale: TwoSymbolLocale;
}): ((dispatch: Dispatch) => Promise<void>) => {
  switch (sectionItem.type) {
    case StoreItemType.VIRTUAL_GOOD:
      return async (dispatch) => {
        dispatch(startFetchVirtualItems(sectionItem.group as string));
        const virtualItems = await StoreDataService.getVirtualItemsByGroup({
          groupId: sectionItem.group as string,
          projectId,
          auth,
          locale,
        });
        dispatch(fetchVirtualItems(virtualItems, sectionItem.group as string));
      };
    case StoreItemType.VIRTUAL_CURRENCY:
      return async (dispatch) => {
        dispatch(startFetchVirtualCurrencies());
        const virtualCurrencies = await Promise.all([
          StoreDataService.getVirtualCurrencies({ projectId, auth, locale }),
          StoreDataService.getVirtualCurrencyPacks({ projectId, auth, locale }),
        ]);
        dispatch(fetchVirtualCurrencies(virtualCurrencies));
      };
    case StoreItemType.UNIT:
      return async (dispatch) => {
        dispatch(startFetchUnits());
        const units = await StoreDataService.getGames({
          projectId,
          auth,
          locale,
        });
        dispatch(fetchUnits(units));
      };
    case StoreItemType.BUNDLE:
      return async (dispatch) => {
        dispatch(startFetchBundles());
        const bundles = await StoreDataService.getBundles({
          projectId,
          auth,
          locale,
        });
        dispatch(fetchBundles(bundles));
      };
    default:
      throw new Error('Unknown store item type');
  }
};

export const constructAuthCredentials = (renderMode: RenderMode) => {
  const userToken = getCookie(CookieNames.USER_LOGIN_TOKEN);
  const auth =
    userToken && renderMode !== RenderMode.EDITING
      ? { auth: true, userToken }
      : { auth: false, xUnauthorizedId: uuid() };
  return auth as StoreApiAuth;
};

export const distinctVirtualItems = (
  previousVis: VirtualItem[],
  currentVis: VirtualItem[]
): VirtualItem[] => {
  const nextVis: VirtualItem[] = [...previousVis];
  currentVis.forEach((vi) => {
    if (nextVis.every(({ sku }) => sku !== vi.sku)) {
      nextVis.push(vi);
    }
  });
  return nextVis;
};
