import { FiveSymbolLocale } from '@site-builder/common/src/types/locale';
import React, { useEffect, useState } from 'react';

import { ReactQuillWrapper } from './ReactQuill';
import { TextSize, FieldName, LocalizedString } from './types';
import { Wrapper } from './Wrapper';

import './style.pcss';

interface CommonEditorTextProps {
  onBlur: (value: string) => void;
  onCheckoutBlock: () => void;
  field: FieldName;
  locale: FiveSymbolLocale;
  isServerSideRender: boolean;
  style?: React.CSSProperties;
  size?: TextSize;
  className?: string;
  dataId?: string;
  disableTextAlignSettings?: boolean;
}
interface LegacyEditorTextProps {
  localizedText?: undefined;
  values: Partial<{ [K in FieldName]: LocalizedString }>;
  notLocale?: undefined;
}
interface LegacyEditorTextPropsWithoutLocale {
  localizedText?: undefined;
  values: Partial<{ [K in FieldName]: string }>;
  notLocale: boolean;
}
interface ModernEditorTextProps {
  localizedText: LocalizedString;
  values?: undefined;
  notLocale?: undefined;
}
export type EditorTextProps = (
  | ModernEditorTextProps
  | LegacyEditorTextProps
  | LegacyEditorTextPropsWithoutLocale
) &
  CommonEditorTextProps;

export const EditorText = ({
  localizedText,
  locale,
  field,
  values,
  size,
  isServerSideRender,
  className,
  onCheckoutBlock,
  onBlur,
  disableTextAlignSettings,
  notLocale = false,
  style = {},
  dataId = '',
}: EditorTextProps) => {
  const getInitialText = ({
    localizedText,
    locale,
    notLocale,
    values,
    field,
  }) => {
    let text = '';
    if (localizedText) {
      text = localizedText[`${locale}`] as string;
    }
    if (values) {
      if (notLocale) {
        text = (values as Record<keyof typeof field, string>)[`${field}`];
      } else {
        text = (values as Record<keyof typeof field, LocalizedString>)[
          `${field}`
        ][`${locale}`];
      }
    }
    return text;
  };

  const [text, setText] = useState(
    getInitialText({
      localizedText,
      locale,
      notLocale,
      values,
      field,
    })
  );
  useEffect(() => {
    setText(
      getInitialText({
        localizedText,
        locale,
        notLocale,
        values,
        field,
      })
    );
  }, [localizedText, locale, notLocale, values, field]);

  const saveText = (value: string) => {
    setText(value);
    if (localizedText) {
      localizedText[`${locale}`] = value;
    }
    if (values) {
      if (notLocale) {
        values[`${field}`] = value;
      } else {
        (values[`${field}`] as LocalizedString)[`${locale}`] = value;
      }
    }
    if (onBlur) {
      onBlur(value);
    }
  };

  return (
    <Wrapper
      innerHTML={isServerSideRender ? { __html: text } : undefined}
      className={className}
      dataId={dataId}
      field={field}
      size={size}
      style={style}
    >
      {!isServerSideRender ? (
        <ReactQuillWrapper
          initialText={text}
          onBlur={saveText}
          onFocus={onCheckoutBlock}
          disableTextAlignSettings={disableTextAlignSettings}
        />
      ) : undefined}
    </Wrapper>
  );
};
