"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformInventoryOptions = void 0;
const transformInventoryOptions = (inventoryOption) => {
    return {
        consumable: inventoryOption.consumable
            ? {
                usagesCount: inventoryOption.consumable.usages_count,
            }
            : null,
        expirationPeriod: inventoryOption.expiration_period,
    };
};
exports.transformInventoryOptions = transformInventoryOptions;
