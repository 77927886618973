"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BundleItem = void 0;
class BundleItem {
    constructor(item, quantity) {
        this.originalItem = item;
        this.quantity = quantity;
    }
}
exports.BundleItem = BundleItem;
