"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unit = void 0;
const attributes_1 = require("../attributes");
const game_key_1 = require("../game-key/game-key");
const groups_1 = require("../groups");
const item_1 = require("../univeral-item/types/item");
class Unit {
    constructor(item) {
        this.type = item_1.ItemType.UNIT;
        this.isFree = false;
        this.virtualPrices = null;
        this.attributes = [];
        this.canBeBought = true;
        this.groups = [];
        this.isAbleToBuyForRealPrice = true;
        this.hasVirtualPrice = false;
        this.sku = item.sku;
        this.name = item.name;
        this.description = item.description;
        this.imageUrl = item.image_url;
        this.attributes = item.attributes?.map(attributes_1.transformAttribute);
        this.groups = item.groups?.map(groups_1.transformGroup);
        this.unitItems = item.unit_items.map((game) => new game_key_1.GameKey({
            ...game,
            name: item.name,
            description: item.description,
            image_url: item.image_url,
            attributes: item.attributes,
            groups: item.groups,
        }));
    }
    getVirtualPrice() {
        return this.virtualPrices;
    }
    get price() {
        const gamesSortPrice = this.unitItems.sort((game1, game2) => Number(game1.price?.amount) - Number(game2.price?.amount));
        return gamesSortPrice[0].price;
    }
}
exports.Unit = Unit;
