"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bundle = void 0;
const item_1 = require("../univeral-item/types/item");
const universal_item_1 = require("../univeral-item/universal-item");
const utils_1 = require("../utils");
const bundle_item_1 = require("./bundle-item");
const bundle_1 = require("./types/bundle");
class Bundle extends universal_item_1.StoreItem {
    constructor(item) {
        super(item);
        this.type = item_1.ItemType.BUNDLE;
        this.bundleType = bundle_1.BundleType.STANDARD;
        this.content = item.content.map((item) => {
            return new bundle_item_1.BundleItem((0, utils_1.fromStoreApiToStoreItem)(item), item.quantity);
        });
    }
}
exports.Bundle = Bundle;
